import React, {useEffect} from 'react';
import './App.css';
import Routex from './routes/Routes';
import { ContextProvider } from "./context/context";
import { ContextEffectsProvider } from "./context/contextEffects";
// import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { StyledEngineProvider } from '@mui/material/styles';
import ReactGA from "react-ga";
const TRACKING_ID = "G-BWBT2S7D0D"; // OUR_TRACKING_ID

// import Analytics from './component/Analytics/Analytics'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600, //dafault = 600
      md: 960,
      lg: 1280,
      xl: 1367, //def 1920
    },
  },
})


const App = () => {
  useEffect(() => {
    //Analytics()
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  //const [bool, setBool] = useReducer((current) => !current, false);
  //const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className="App">
          <ContextProvider>
            <ContextEffectsProvider>
              <Routex />
            </ContextEffectsProvider>
          </ContextProvider>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
