import React from 'react';
import { Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    paper: {
        margin: '1vh auto',
        display: 'flex',
        width: '100%',
        maxWidth: '870px',
        backgroundColor: '#52658D',
        overflowY: 'hidden',
      },
    titleContainer: {
        display: 'flex',
        width: '100%',
        height: '40px',
        alignContent: 'center',
        
    },
    bandTitle: {
        marginTop: '-10px',
        height: '50px',
        width: '94px',
        backgroundImage: 'url(/banda.png)',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    },
    circTitle: {
        marginTop: '20px',
        height: '10px',
    },
    title: {
        padding: '0',
        margin: '12px 0 0 5px',
        textAlign: 'left',
        color: 'white',
        fontSize: '1rem',
        fontWeight: '500',
    },

}));

const SubHeader = (props) => {

    const { titleContainer, bandTitle, circTitle, title, paper } = useStyles();


    return (
        <>
            <Grid container >
                <Grid item xs={'auto'} sm={1} md={1} style={{}} />
                <Grid item xs={12} sm={10} md={10} style={{}} >
                    <Paper elevation={3} className={paper}>
                        <div className={titleContainer}>
                            <div className={bandTitle} />
                            <img className={circTitle} src={'/circulo.png'} alt=""/>
                            <h3 className={title}> {props.title} </h3>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={'auto'} />
            </Grid>
        </>
    )
}

export default SubHeader;