import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    //display: 'flex',
    //paddingTop:'5rem',
    //width: '100%',
    //marginTop: '2vh',
    maring: 0,
    padding: 0

  },
  topContainer: {
    // width: 'auto',
    //marginLeft:'10rem',
    // alignItems: 'center',
    flexDirection: 'column',
    //marginBottom: '1rem',
    display: 'flex',
    //justifyContent: 'center',
    //alignItems: 'center',
  },
  container: {
    // maxHeight: '70vh',    
    margin: 'auto',
  },
  rowContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    margin: '0',
    maxWidth: '800px'
  },
  searchContainer: {
    width: '100%',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  menuItems: {
    width: '100%'
  },
  select: {
    flex: '1 1 auto',
  },
  paperTable: {
    // padding: theme.spacing(2),
    margin: '1vh auto',
    width: '98vw',
    maxWidth: '1366px',
    marginBottom: '2vh',
   // border: '1px solid red'
  },
  formControl: {
    minWidth: 160,
  },
  searcher: {
    textAlign: 'center',
    margin: '0 auto',
  },
  drawerPaper: {
    background: 'transparent', ///fondo total
    paper: {
      background: 'red !important'
    }
  },
  BackdropProps: {
    background: 'transparent',
    border: '1px solid red',
    marginTop: '0'
  },
  sideDiv: {
    minWidth: '250px',
    border: '1px solid red'
  },
  breadcrumbs: {
    height: '20px',
    fontSize: '12px',
    color: 'rgba(75, 103, 163,0.7)',
    paddingTop: '6px',
    overflow: 'hidden'

  },
  pbread: {
    marginTop: 0,
    marginBottom: 0,
  },
  chips: {
    width: '100%',
    marginTop: '15px',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    height: '26px',
    //position: 'absolute',
    zIndex: 999,  
    overflow:"scroll",     
  },
  chip: {

    color: '#52658D',
    fontSize: '0.7rem',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '1px',
    backgroundColor: '#f5f5f5',
  },

  chipIcon: {
    color: 'red',
    border: '1px solid red',
    fontSize: '0.1rem',
  }
});

export { useStyles };
