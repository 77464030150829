import { createControlComponent } from "@react-leaflet/core";
import { Control, DomUtil, DomEvent} from "leaflet";
import { Button } from "@mui/material";
import { Context } from "../../context/context";
import { useContext, useEffect } from "react";
import { useMap } from 'react-leaflet';

let mapButton = Control.extend({
    initialize: function(props) {
        //console.log(props)
        this.deleteViews = props.function;
        this.position = props.position;
    }, 

    onAdd: function (map) {   
        const button = DomUtil.create("button");
        button.textContent = "(X) Eliminar Capas"
        button.style.fontSize= "12px"
        // img.src = "/logo_map3.png";
        button.style.width = "120px"
        button.style.backgroundColor = "transparent" 
        button.style.color = "#0078A8"
        button.style.border = "none"
        button.style.margin = "2px 0 2px 0 "
        button.style.padding = 0
        button.style.cursor = "pointer"   
        DomEvent.addListener(button, "click", () => this.deleteViews());
        return button;
      },

      onRemove: function (map) {},
    });
// export { mapButton };

// const versionInstance = new mapButton("green")
// map.addControl(versionInstance)

const DeleteControl = (props) => {
    const map = useMap();
  
    useEffect(() => {
      const deleteControl = new mapButton(props);
      deleteControl.setPosition(props.position)
      map.addControl(deleteControl);
      return () => map.removeControl(deleteControl);
    }, [props]);
  
    return null;
  };

export {DeleteControl}

// const { red } = useContext(Context)
/*
export const MapButtonControl = (props) => {
    // (props) => new Control.MapButton(props)
    console.log(props)
    const map = props.map
    const versionInstance = new mapButton(props.color)
    map.addControl(versionInstance)
    console.log(versionInstance)
    return versionInstance
}*/


 