import React, { useEffect, useContext } from 'react'
import { Context } from "../../context/context";
// import { ContextEffects } from '../../context/contextEffects';
import Fade from 'react-reveal/Fade';

const Logos = ({duration}) => {
    
    const { notMain } = useContext(Context) 

    useEffect(() => {
        //console.log('duration', duration)
    }, [notMain]);
    return (
        <>
            {notMain === true ?
                //  fader(<img src="/logogeoportal2.png" height='60px' width='338px' />, 1000, 0)

                <Fade left duration={duration !== null ? duration : 1000} delay={0}>
                    <img src="/logogeoportal2.png" height='60px' width='338px' alt="logo geoportal endevam"/>
                </Fade>

                :
                <div>
                    <Fade left duration={duration !== null ? duration : 1000} delay={0}>
                        <img src="/logogeoportal1.png" height='60px' width='338px' alt="logo geoportal endevam"/>
                    </Fade>
                </div>
            }
        </>
    )
}

export default Logos;