import React, { useState, useEffect, useContext } from 'react'
import Headers from '../Segments/Headers';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material'
import { Context } from "../../context/context";
import { ContextEffects } from "../../context/contextEffects"
import { useStyles } from "../../styles/useStyle.js";
import { Search, LastPage, FirstPage, KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import { IconButton, useTheme, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { AiOutlineFilePdf } from 'react-icons/ai' //AiOutlineFileExcel
import jsPDF from "jspdf";
import "jspdf-autotable";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

const Tabla = ({ comercios, loadingscom }) => {
	var pdfimg = new Image()
	pdfimg.src = './logo_pdf.jpg'


	const classes = useStyles();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(8);
	const { localidad, censo, group, categoria, theloc, therel, therub, thecat, thegru, busquedaString,
		getComerciosLRRC, setComercios, setLoadingscom} = useContext(Context);
	const { animatedDiv, isTabletOrMobile } = useContext(ContextEffects)
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('nombre');

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const useStyles1 = makeStyles((theme) => ({
		root: {
			flexShrink: 0,
			//marginLeft: theme.spacing(2.5),
		},
	}));

	function capitalizeFirstLetters(str, col) {
		//console.log('str: ' + str)
		//console.log('col: ' + col)
		let arr = str.split(" ");
		//console.log('arr: ' + arr);
		for (let i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}
		let str2 = arr.join(" ");
		//console.log('str2: ' + str2)
		if (col === 'direccion') {
			let arr2 = str2.split("/")
			//console.log(arr2)
			for (let i = 0; i < arr2.length; i++) {
				arr2[i] = arr2[i].charAt(0).toUpperCase() + arr2[i].slice(1);
			}
			let str3 = arr2.join("/");
			return str3   ////Pone mayuscula despues de la / en las direcciones con esquina
		} else { return str2 }

	}

	function TablePaginationActions(props) {
		const classes = useStyles1();
		const theme = useTheme();
		const { count, page, rowsPerPage, onPageChange } = props;
		//console.log(count, page, rowsPerPage, onPageChange)

		const handleFirstPageButtonClick = (event) => {
			onPageChange(event, 0);
		};

		const handleBackButtonClick = (event) => {
			onPageChange(event, page - 1);
		};

		const handleNextButtonClick = (event) => {
			onPageChange(event, page + 1);
		};

		const handleLastPageButtonClick = (event) => {
			onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
		};

		return (
			<div className={classes.root}>
				<IconButton
					onClick={handleFirstPageButtonClick}
					disabled={page === 0}
					aria-label="first page"
					size="large">
					{theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
				</IconButton>
				<IconButton
					onClick={handleBackButtonClick}
					disabled={page === 0}
					aria-label="previous page"
					size="large">
					{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
				</IconButton>
				<IconButton
					onClick={handleNextButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="next page"
					size="large">
					{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
				</IconButton>
				<IconButton
					onClick={handleLastPageButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="last page"
					size="large">
					{theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
				</IconButton>
			</div>
		);
	}

	const handleRequestSort = async (event, property) => {
		console.log(property)
		console.log("order", order)
		
		let loc = theloc
		let rel = therel
		let rub = therub
		let cate = thecat
		let gru = thegru
		let busqueda = ''
		let boolg = false
		let orderby = property
		let ord = order
		//console.log(theloc, therel, therub, thecat, thegru, busquedaString, boolg)
		//Change the order of the list API REQUEST
		

		console.log(loc, rel, gru, cate, gru, busqueda, boolg, orderby, ord)
		await getComerciosLRRC(loc, rel, gru, cate, busqueda, boolg, gru, boolg, orderby, ord)
			.then(data => {
				console.log(data)
				setComercios(data);
				setLoadingscom(false)
			})

		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
		
		console.log("order", order)
		
	};

	const exportPDF = () => {
		const unit = "px";
		const size = "A4"; // Use A1, A2, A3 or A4
		const orientation = "landscape"; // portrait or landscape
		const totalPagesExp = '{total_pages_count_string}'
		//const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);

		//getting the nombres
		var pdfLoc = Object.values(localidad).find(loc => loc.id === theloc).nombre.toUpperCase();
		var pdfCen = Object.values(censo).find(cen => cen.id === therel).nombre.toUpperCase();
		var pdfRub = Object.values(group).find(r => r.id === thegru).nombre.toUpperCase();
		var pdfCat = Object.values(categoria).find(cat => cat.id === thecat).nombre.toUpperCase();
		console.log(busquedaString)
		var pdfSearch
		if (busquedaString === '' || busquedaString === undefined || busquedaString == null) { pdfSearch = '' }
		else { pdfSearch = ' - ' + busquedaString }

		if (pdfLoc === 'TODAS') {
			pdfLoc = 'VALLE MEDIO Y RÍO COLORADO'
		}

		///fechahora
		var toda = new Date();
		var dd = toda.getDate();
		var mm = toda.getMonth() + 1;
		var yyyy = toda.getFullYear();
		var today = dd + '/' + mm + '/' + yyyy;

		const headers = [["NOMBRE", "DIRECCION", "N°", "LOCALIDAD", "CATEGORIA"]];

		let rows = []
		let row = comercios.forEach(e => {
			row = [e.fantasia.toUpperCase(),
			capitalizeFirstLetters(e.direccion),
			e.numero,
			capitalizeFirstLetters(e.localidad),
			e.categoria];
			rows.push(row);
			row = [];
		});

		// doc.text(title, marginLeft, 40);
		doc.autoTable({
			head: headers,
			body: rows,
			didDrawPage: function (data) {
				//////////////////////////////////////////////////////////////////////// Header
				doc.setFontSize(20)
				doc.setTextColor(40)
				if (pdfimg) {
					doc.addImage(pdfimg, 'JPEG', 29, 5, 140, 35)
				}
				//texto titulo localidad
				doc.setFontSize(15);
				doc.setTextColor(41, 128, 186);
				doc.text(pdfLoc, 185, 21);
				//linea
				doc.setDrawColor(41, 128, 186);
				doc.line(185, 24, 285, 24);
				// texto titulo categorias
				doc.setFontSize(11);
				doc.setTextColor(41, 128, 186);
				doc.text(pdfCen + " - " + pdfRub + " - " + pdfCat + pdfSearch, 240, 36, { maxWidth: 325 })
				//fecha
				doc.setFontSize(9);
				doc.text(today, 570, 38);
				//linea
				doc.setDrawColor(41, 128, 186);
				doc.line(29, 42, 600, 42);
				//////////////////////////////////////////////////////////////////////	// Footer
				var str = 'Página ' + doc.internal.getNumberOfPages()
				// Total page number plugin only available in jspdf v1.0+
				if (typeof doc.putTotalPages === 'function') {
					str = str + ' de ' + totalPagesExp
				}
				//var pageSize = doc.internal.pageSize
				//var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
				doc.setDrawColor(41, 128, 186);
				doc.line(29, 425, 600, 425);
				doc.setFontSize(9);
				// doc.setFontStyle("italic");
				doc.text(str, 550, 436);
				doc.setFontSize(7);
				doc.text('© EnDeVam - Ente de Desarrollo Regional del Valle Medio. - 2022', doc.internal.pageSize.width / 2, 440, {
					align: 'center'
				})
			},
			margin: { top: 50 },
		})
		if (typeof doc.putTotalPages === 'function') {
			doc.putTotalPages(totalPagesExp)
		}

		doc.save("Endevam - Catalogo.pdf")
	};

	useEffect(() => {
		console.log('uef TABLA')
		console.log("comercios", comercios)
	}, []);

	return (
		<>
			{animatedDiv(
				<>
					<div style={{ height: '', backgroundColor: '#f2f2f2', margin: isTabletOrMobile ? '50px 0 0 0' : 0 , padding: '0'}}>
						<Paper className={classes.paperTable}>
							<TableContainer className={classes.container}>
								<Table stickyHeader aria-label="sticky table">
									<TableHead style={{}} >
										<TableRow style={{}} >
											{Headers.map((column) => (
												<TableCell
													key={column.id}
													style={{
														padding: '0',
														backgroundColor: '#52658D',
														borderRight: '1px solid white'
													}}>
													<TableSortLabel
														align={column.align}
														style={{
															minWidth: column.minWidth,
															backgroundColor: '',
															color: 'white',
															fontWeight: '700',
															textAlign: 'left',
															padding: '8px',
															paddingLeft: '1vw',
														}}
														active={orderBy === column.id}
														direction={orderBy === column.id ? order : 'asc'}
														onClick={(e) => handleRequestSort(e, column.id)}
														 disabled={loadingscom}
													>
														{column.label.toUpperCase()}
													</TableSortLabel>
												</TableCell>

											))}
										</TableRow>
									</TableHead>
									<TableBody >
										{loadingscom ? (
											<TableRow style={{}}>
												<TableCell align="center" colSpan="5" style={{ margin: 'auto', height: '240px' }}>
													<div style={{ fontSize: '80px', height: '80px', color: '#8aafc2' }}><Search color='inherit' fontSize='inherit' /></div>
													<p style={{ color: '#a1a1a1' }}>Seleccione los parámetros de Búsqueda.</p>
												</TableCell>
											</TableRow>
										) :
											(
												<>
													{comercios.length === 0 ?
														<TableRow style={{}}>
															<TableCell align="center" colSpan="5" style={{ margin: 'auto', height: '240px' }}>
																<div style={{ fontSize: '80px', height: '80px', color: '#8aafc2' }}><Search color='inherit' fontSize='inherit' /></div>
																<p style={{ color: '#a1a1a1' }}>La búsqueda actual no produjo resultados.</p>
															</TableCell>
														</TableRow>
														//esto deberia pasar cuando el usuario cambia valores en retrospectiva, selectors necesita actualizarse
														:
														//console.log('comercios', comercios),
														comercios.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
															return (
																<>
																	<TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
																		{Headers.map((column) => {
																			let value = ''
																			if (column.id === 'fantasia') { value = row['fantasia'].toUpperCase() }
																			else if (column.id === 'direccion' || column.id === 'localidad') { value = capitalizeFirstLetters(row[column.id], column.id) }
																			else { value = row[column.id] }
																			return (
																				<TableCell key={column.id} align={'left'}>
																					{column.format && typeof value === 'number' && typeof value === 'string' ? column.format(value) : value}
																				</TableCell>
																			);
																		})}
																	</TableRow>
																</>
															)
														})
													}
												</>
											)}
									</TableBody>
								</Table>
							</TableContainer>
							{loadingscom ? (<></>
							) : (
								comercios && comercios.length > 0

								&& <TablePagination
									className={classes.tablePagination}
									rowsPerPageOptions={[5, 8, 10, 12, 15, 20]}
									labelRowsPerPage={'Items por página: '}
									component="div"
									count={comercios.length}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									style={{ backgroundColor: 'transparent' }}
									SelectProps={{
										id: 'tableSelect',
									}}
									ActionsComponent={TablePaginationActions}
								/>
							)}
						</Paper>


					</div>
					<div style={{ width: '90vw', maxWidth: '1366px', justifyContent: 'center',
					 margin: '1vh auto 3vh auto', display: 'flex', color: 'rgba(0, 0, 0, 0.54)', borderTop:'1px solid #EEE' }}>
						
						
							
							<Button variant="outlined" size="small" style={{textTransform: 'none',}}
							disabled={comercios === undefined || comercios.length === 0}
									onClick={() => exportPDF()}
							startIcon={<SimCardDownloadIcon size="inherit" style={{marginRight: 0}} />}>
								<span style={{marginTop:'3px'}}>Descargar PDF</span>
							</Button>
					</div>
				</>
				, 300)
			}
			
		</>
	)
}
export default Tabla;