import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

const L = require("leaflet");
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const MiniMap = (props) => {
  console.log(props.text)
  const position = [-39.271706, -65.672270];

  return (
    <>
      {props.text && <p style={{margin:'0 13px', padding:0, textAlign:'left', fontSize:'0.9rem', color:'#52658D'}}>Nuestra Ubicación:</p> }
      <MapContainer style={{ margin: '0 auto', height: "200px", width: "96%" }} center={position} zoom= {15} scrollWheelZoom={true} >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          position={position}>
        </Marker>
      </MapContainer>
      {props.text && <div style={{ display: 'flex' }}>
        <p style={{ fontSize: '10px', margin: '0 auto' }}>Choele Choel (8360), Río Negro - Ruta Nacional 22, Km 1000 </p>
      </div>}
    </>
  );
}

export default MiniMap;