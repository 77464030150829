import React, { useEffect, useState, useContext } from 'react';
import {  TextField, Button, Card, Grid, CardContent, CardActions, Divider } from "@mui/material";

import { makeStyles } from '@mui/styles';
import MiniMap from '../MiniMap/MiniMap'
import Redes from '../Redes/Redes'
// import axios from 'axios'
import { Context } from "../../context/context";
import { ContextEffects } from "../../context/contextEffects"
import SubHeader from '../SubHeader/SubHeader'
import MiniFooter from './../Footer/MiniFooter'
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles(() => ({
    miniDiv: {
        backgroundColor: "#52658D",
        paddingTop: '0',
        paddingLeft: '0',
        minHeight: 'auto',
        height: '7vh',
        margin: '0 auto',
        marginTop: '0.5vh',
    },

    container: {
        //width: '360px',
        boxShadow: '0px 0 5px -2px #888',
        padding: '1vh 2vw',
        //marginBottom: '2vh',
        margin: '0 2vw 2vh auto',

    },
    container2: {
        //width: '280px',
        boxShadow: '0px 0 5px -2px #888',
        marginBottom: '2vh',

    },
    container3: {
        //width: '280px',
        boxShadow: '0px 0 5px -2px #888',
        marginBottom: '2vh'
    },
    cardsSubContainer: {
        display: 'flex',
        margin: '0 auto',
        width: 'auto',
    },
    cardsContainer: {
        //width: '98vw',
        overflow: 'auto',
        margin: '0 auto',
        marginTop: '1vh',
        display: 'flex',
        maxWidth:'870px'
    },
    campo: {
        marginBottom: "2vh",
        width: '45%'
    },
    mensaje: {
        width: '90%',
        marginBottom: "2vh"
    },
    divs: {
    },
    mail: {
        paddingTop: '0vh'
    },
    actions: {
        margin: '0 auto'
    },
    button: {
        margin: '0 auto',
        width: '80%',
        backgroundColor: '#52658D',
        color: 'white',
        "&:hover, &:focus": {
            backgroundColor: '#52658D',
        }
    },
    title: {
        width: '98%',
        display: 'flex',
        fontSize: '1.5rem',
        color: '#68789b',
        borderBottom: '1px solid #7d8caa',
        margin: '1vh auto 1vh auto',
        paddingBottom: '4px',
        '& h3': {
            padding: '0',
            margin: '0',
            fontWeight: '400',
        },
    },
}));

const initialState = {
    nombre: '',
    apellido: '',
    email: '',
    localidad: '',
    telefono: '',
    asunto: '',
    mensaje: '',
}

const Contacto = () => {

    const {  container, container2, container3, cardsContainer, campo, mail, actions, button,  mensaje, } = useStyles();
    const [object, setObject] = useState(initialState)
    const [errors, setErrors] = useState(initialState)
    const [nombreValid, setNombreValid] = useState(true)
    const [apellidoValid, setApellidoValid] = useState(true)
    const [emailValid, setEmailValid] = useState(true)
    const [localidadValid, setLocalidadValid] = useState(true)
    const [asuntoValid, setAsuntoValid] = useState(true)
    const [mensajeValid, setMensajeValid] = useState(true)
    const [formValid, setFormValid] = useState(false)
    //const [noErrors, setNoErrors] = useState(false)  
    const [enviando, setEnviando] = useState(false)
    const [exito, setExito] = useState(undefined)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' })
    const { postFormMail, setRouteName } = useContext(Context);
    const { animatedDiv } = useContext(ContextEffects)

    const onSubmit = () => {
       // console.log('SUBMIT')
        validateAll()
        //console.log(errors)
        checkErrors()
    }

    const validateAll = () => {
        validateField({ target: { value: object.nombre, id: 'nombre' } })
        validateField({ target: { value: object.apellido, id: 'apellido' } })
        validateField({ target: { value: object.email, id: 'email' } })
        validateField({ target: { value: object.localidad, id: 'localidad' } })
        validateField({ target: { value: object.asunto, id: 'asunto' } })
        validateField({ target: { value: object.mensaje, id: 'mensaje' } })
    }

    const checkForm = async () => {
        if (formValid === true) {
            //console.log('FORM VALID')
            await postFormMail(object)
                .then(res => {
                    console.log(res)
                    setObject(initialState)
                    setErrors(initialState)
                    setEnviando(false)
                    if(res !== "error") {
                      setExito(true)
                    }
                    else {
                        setExito(false)
                      }
                })
                .catch(error => { console.log(error); })
        }
        else {
           // console.log("FORM INVALID")
        }
        setEnviando(false)
    }
    const checkErrors = async () => {
        console.log(errors)
        setEnviando(true)
        //setExito(false)
        console.log('CHECK ERRORS:', errors, object)
        if (errors.nombre === '' && errors.apellido === '' && errors.email === '' &&
            errors.localidad === '' && errors.asunto === '' && errors.mensaje === '' &&
            object.nombre !== '' && object.apellido !== '' && object.email !== '' &&
            object.localidad !== '' && object.asunto !== '' && object.mensaje !== '') {
            console.log('IF TRUE')
            if (formValid === true) { setFormValid(false) }
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(setFormValid(true))
                    //  setNoErrors(true))
                }, 200);
            });
        }
        else {
            console.log('IF FALSE')
            //setNoErrors(false)
            setFormValid(false)
            setEnviando(false)
        }
    }

    const validateField = (e) => {

        const value = e.target.value
        const name = e.target.id
        console.log('v:', value, 'n:', name)
        let x
        if (name === 'nombre') {
            setNombreValid(value.length >= 3)
            x = value.length >= 3 ? '' : '* Debe contener al menos 3 caracteres'
            setErrors({ ...errors, nombre: x })
        }
        else if (name === 'apellido') {
            setApellidoValid(value.length >= 3)
            x = value.length >= 3 ? '' : '* Debe contener al menos 3 caracteres'
            setErrors({ ...errors, apellido: x })
        }
        else if (name === 'email') {
            // emailValid = value.length >= 3
            setEmailValid(value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
            x = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? '' : '* Ingese una dirección de Email Válida'
            setErrors({ ...errors, email: x })
        }
        else if (name === 'localidad') {
            setLocalidadValid(value.length >= 3)
            x = value.length >= 3 ? '' : '* Debe contener al menos 3 caracteres'
            setErrors({ ...errors, localidad: x })
        }
        else if (name === 'asunto') {
            setAsuntoValid(value.length > 0)
            x = value.length > 0 ? '' : '* Campo Obligatorio'
            setErrors({ ...errors, asunto: x })
        }
        else if (name === 'mensaje') {
            setMensajeValid(value.length <= 300 && value.length > 0)
            x = value.length <= 300 && value.length > 0 ? '' : '* Debe contener entre 1 y 300 caracteres'
            setErrors({ ...errors, mensaje: x })
        }
    }

    const handleChange = (e) => {
        console.log(e.target)
        const val = e.target.value
        const name = e.target.id
        console.log(name, val)
        setObject({
            ...object,
            [name]: val
        })
    }

    useEffect(() => {
        setRouteName('Contacto')
        if (exito === true) {
            //setExito(false)
            console.log(object)
        }
        else { checkForm() }
    }, [formValid]);

    return (
        <>
            <SubHeader title='CONTACTO' />

            <div className={cardsContainer}>
                <Grid container>
                    <Grid item xs={'auto'}  style={{}} />
                    <Grid item xs={12}   style={{}} >
                        <Grid container style={{}}>

                            <Grid item xs={12} md={8} style={{ display: 'flex', justifyContent: 'center', maxWidth: '870px' }} >
                                {animatedDiv(
                                    <Card className={container} variant="outlined">
                                        <form autoComplete="off">
                                            <CardContent style={{ width: '100%', paddingLeft: '0', paddingBottom: '0', paddingTop: '15px' }}>

                                                <div style={{ display: 'flex' }}><TextField value={object.nombre} size={"small"} onBlur={validateField} error={!nombreValid} helperText={errors.nombre} required={true} className={campo}
                                                    id="nombre" label="Nombre" variant="outlined" onChange={handleChange} style={{ marginRight: '2vw' }} />
                                                    <TextField value={object.apellido} size={"small"} onBlur={validateField} error={!apellidoValid} helperText={errors.apellido} required={true} className={campo}
                                                        id="apellido" label="Apellido" variant="outlined" onChange={handleChange} /></div>
                                                <div style={{ display: 'flex' }}>
                                                    <TextField value={object.email} size={"small"} onBlur={validateField} error={!emailValid} helperText={errors.email} type="email" required={true} className={campo}
                                                        id="email" label="Email" variant="outlined" onChange={handleChange} style={{ marginRight: '2vw' }} />
                                                    <TextField value={object.localidad} size={"small"} onBlur={validateField} error={!localidadValid} helperText={errors.localidad} required={true} className={campo}
                                                        id="localidad" label="Localidad" variant="outlined" onChange={handleChange} /></div>
                                                <div><TextField value={object.telefono} size={"small"} type="tel" className={campo}
                                                    id="telefono" label="Teléfono" variant="outlined" onChange={handleChange} /></div>
                                                <Divider variant="middle" style={{ margin: '1rem 0' }} />
                                                <div className={mail}>

                                                    <div>
                                                        <TextField value={object.asunto} size={"small"} onBlur={validateField} error={!asuntoValid} helperText={errors.asunto} required={true} className={campo} id="asunto" label="Asunto" InputLabelProps={{ shrink: true }} variant="outlined"
                                                            onChange={handleChange} />
                                                    </div>

                                                    <div>
                                                        <TextField
                                                            value={object.mensaje}
                                                            className={mensaje}
                                                            onBlur={validateField}
                                                            error={!mensajeValid}
                                                            helperText={errors.mensaje}
                                                            required={true}
                                                            onChange={handleChange}
                                                            InputLabelProps={{ shrink: true }}
                                                            size={"small"}
                                                            id="mensaje"
                                                            label="Mensaje"
                                                            variant="outlined"
                                                            placeholder="Deje su Mensaje.."
                                                            multiline
                                                            rows={3}
                                                            fullWidth={true}
                                                        />
                                                    </div>
                                                    <Divider />
                                                </div>
                                            </CardContent>
                                            <CardActions className={actions}>
                                                <Button className={button}
                                                    //disabled={!noErrors}
                                                    variant="contained" id='sendButton'
                                                    //type='submit'
                                                    onClick={() => onSubmit()}> Enviar </Button>
                                            </CardActions>
                                            {enviando ? <p>Enviando...</p> : <></>}
                                            {exito == true ? <p>Su mensaje se envió con éxito!</p> : <></> }
                                            {exito == false ? <p style={{color:'#d32f2f'}}>Ocurrió un problema. Por favor, comuníquese por otro medio.</p> : <></>}
                                        </form>
                                    </Card>
                                    , 200)}

                            </Grid>
                            {!isTabletOrMobile &&  //aca se rompe cunado haces resize
                                <Grid item xs={12} md={4} style={{}}>
                                    <Grid container style={{}} >
                                        <Grid item xs={1} sm={'auto'} style={{}} />
                                        <Grid item xs={10} sm={12} style={{}}>
                                            {animatedDiv(<Card className={container2} variant="outlined">
                                                <Redes />
                                            </Card>, 400)}
                                        </Grid>
                                        <Grid item xs={1} sm={1} style={{}} />
                                    </Grid>
                                    <Grid container style={{}} >
                                        <Grid item xs={1} sm={'auto'} />
                                        <Grid item xs={10} sm={12} >
                                            {animatedDiv(
                                                <Card className={container3} variant="outlined">
                                                    <CardContent style={{ width: '100%', padding: '0', paddingTop: '2px' }}>
                                                        <MiniMap text={true} />
                                                    </CardContent>
                                                </Card>
                                                , 600)}
                                        </Grid>
                                    </Grid>
                                </Grid>

                            }
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            {
                isTabletOrMobile &&
                <MiniFooter />
            }
        </>
    )
}

export default Contacto;
