import React, { useEffect, useContext } from 'react'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Context } from "../../context/context";

const Search = ({ Nombre, dataset, onClick, value }) => {
	const { setSearch, setValueSearch, mobile, getCateName} = useContext(Context);

	const onSelect = (event, key) => {
		//si la seleccion es diferente a el primer item
		if (key.props.value !== Nombre) {
			setSearch(Nombre, event.target.value)
			onClick(Nombre, key.props.name, key.props.value, key.props.id2)
		}
	}

	const initial = () => {
		//Toma el valor de la entrada y devuelve el nombre de la variable en el context
		let val = setValueSearch(Nombre)
		value = val
	}

	useEffect(() => {
		 console.log("------search------")
		 console.log(Nombre, dataset, value)
		initial()
	}, []);

	return (
		<div>
			<FormControl style={{width: mobile ? '160px' :  '180px'}} variant="outlined" size="small">
				<InputLabel id={Nombre}>{Nombre}</InputLabel>
				<Select
					label={Nombre}
					defaultValue={Nombre}
					value={value}
					id={Nombre}
					name={Nombre}
					//labelWidth={90}
					onChange={onSelect}
					// IconComponent={!mobile ? ArrowDropDownIcon : ArrowDropDownIcon}
					MenuProps={{
						//getContentAnchorEl: null,
						style: {
							marginLeft: mobile ? '102px' : '0',
							marginTop: mobile ? '-2px' : '0'
						},
						anchorOrigin: {
							vertical: !mobile ? "bottom" : "top",
							horizontal: !mobile ? "center" : "right"
						},
						MenuListProps: {
							style: {
								maxHeight:`calc(100vh - 130px)`,
								paddingTop: 0,
								paddingBottom: 0,
								width: '180px',
								overflowY: 'overlay',
							}
						}
					}}
					SelectDisplayProps={{
						style: {

						}
					}}

				>
					{/* <MenuItem value={Nombre}></MenuItem>  No selecciona el valor default, ni vacio, deberia?*/}
					{dataset && dataset.map(data =>
						<MenuItem
							style={{
								padding: mobile ? '0 8px' : '6px 16px',
								justifyContent: 'flex-start',
								alignItems: 'center',
								width: '180px',
								display: 'flex',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								verticalAlign: 'baseline',
							}}
							key={data.id}
							id={data.id}
							value={data.id}
							name={data.nombre}
							id2={data.id2} >
							{Nombre !== 'CATEGORIA' ? data.nombre.toUpperCase() : getCateName(data.nombre).toUpperCase()}
						</MenuItem>
					)}
				</Select>
			</FormControl>
		</div>
	)
}
export default Search;