import React, { useContext, useEffect } from 'react'
import Tabla from '../Tabla/Tabla';
import Sidebar from '../Sidebar/Sidebar';
import { useStyles } from "../../styles/useStyle.js";
import { Context } from "../../context/context";
import CircularProgress from '@mui/material/CircularProgress';

const MemoTable = React.memo(Tabla);


const Catalogo = () => {
	const { load, comercios, setComercios, loadingscom, setNotMain, setRouteName, routeName, cleanSelectors,categoria,getCategoria } = useContext(Context);
	
	if(categoria == undefined){
		getCategoria()
	  }

	const classes = useStyles();

	useEffect(() => {
		console.log("UEF CATALOGO--------")
		if (routeName === 'Geo') {
			cleanSelectors()
		}
		cleanSelectors()
		setComercios([])
		setRouteName('Catalogo')
		setNotMain(true)
	}, []);

	return (
		<div className={classes.root}>
			<div className={classes.topContainer}>
				<Sidebar className={classes.container} type={'table'}  />
				{load ? (
					<CircularProgress />
				) : (
					<MemoTable
						//la tabla recibe clase y los comercios iniciales					
						className={classes.container}
						comercios={comercios}
						loadingscom={loadingscom}
					/>
				)}
			</div>
		</div>
	)
}
export default Catalogo;