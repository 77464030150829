import { Route, Redirect, useLocation, Routes  } from "react-router-dom"; //BrowserRouter as Router
import React, {useEffect} from "react";
import TopMenu from '../component/TopMenu/TopMenu';
import Geo from '../component/Geo/Geo';
import Catalogo from '../component/Catalogo/Catalogo';
import Contacto from '../component/Contacto/Contacto';
import Inicio from '../component/Inicio/Inicio';
import Servicios from '../component/Servicios/Servicios';
import Descargas from '../component/Descargas/Descargas';


const Routex = () => {

  let {pathname} = useLocation()
  useEffect(() => {
    console.log(pathname)
  }, [pathname]);

  return (
    <>
      <TopMenu />
      <Routes>
        {/* <Redirect from="/:descargas*(/+)" to={pathname.slice(0, -1)} /> */}
        <Route exact path="/"          element={<Inicio />} />
        <Route exact path="/geo"       element={<Geo />} />
        <Route exact path="/catalogo"  element={<Catalogo />} />
        <Route exact path="/descargas" element={<Descargas />} />
       <Route exact path="/servicios" element={<Servicios />} />
        <Route exact path="/contacto"  element={<Contacto />} />
      </Routes>
    </>
  )
}
export default Routex;