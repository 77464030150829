import React, { useContext, useEffect } from 'react'
import { Context } from "../../context/context";
import { ContextEffects } from "../../context/contextEffects"
import { Link, Grid, Card} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { data } from '../../data/dataDescargas'
import SubHeader from './../SubHeader/SubHeader'
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import pdf1 from '../../documents/pdf1.pdf';

const useStyles = makeStyles(() => ({
    container: {       
      },
    imageContainer: {
        height: '320px',
        width: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: 'rgba(0, 0, 0, .02)',
        backgroundBlendMode: 'darken'
    },
    card: {
        width: '240px',
        height: '320px',
        margin: '1vh 1vw 2vh 0',
        padding: '0',
        justifyContent: 'center',
        alignContent: 'center',
        '&:hover': {
             borderBottom:'2px solid transparent',
             marginTop:'calc(1vh - 2px)',
            // marginTop:'-1px',
            cursor:'pointer'
        },    
    },
    cardFoot: {
        // borderTop: '1px dotted #dedede',
        
        display: 'flex',
        margin: '0 auto',
        padding: '0',
        alignItems: 'center',
        justifyContent: 'center',
       
    },
    link: {
        width: '50px',
        display:'flex',
        justifyContent:'space-between',
        textDecoration: 'none',
        cursor: 'pointer',
        color: '#999999',
        padding: '0',
        margin: '0 1vh',
        '&:hover': {
            textDecoration: 'none'
        },
        '& p': {
            padding: 0,
            margin: 0,
        }
    },
    bar: {
        minHeight:"0.83rem",
        fontSize: "0.83rem",
        color: '#999999',
    }
}));


const Descargas = () => {
    const { setNotMain, setRouteName } = useContext(Context);
    const { container, card, imageContainer} = useStyles();
    const { animatedDiv, is489 } = useContext(ContextEffects)
   
    useEffect(() => {
        setRouteName('Descargas')
        setNotMain(true)
    }, []);

    return (
        <>

         
                <SubHeader title='INFORMES' />
                <Grid className={container} container  >
                    <Grid item xs={'auto'} sm={1} style={{  }} />
                    <Grid item xs={12} sm={10}  style={{maxWidth: '870px', margin: '0 auto'}}>
                        <Grid container style={{display: 'flex', justifyContent:  is489 ? 'center' : 'flex-start'}}>
                            {data.map((d, i) =>
                                <div key={i}>
                                    {animatedDiv(
                                        <Grid item xs={12} style={{}}>
                                        <Link onClick={() => window.open(d.descarga)}>
                                            <Card elevation={3} className={card} key={i}>
                                                <div className={imageContainer} alt={d.alt} style={{ backgroundImage: `url(${d.imagen})` }} />
                                            </Card>
                                           </Link>
                                        </Grid>
                                        , (parseInt(i) + 1) * 200)}
                                </div>
                            )}
                        </Grid>
                    </Grid>                    
                    <Grid item xs={'auto'}  sm={1} />
                </Grid>


            

        </>)
}
export default Descargas;