import React,{useEffect, useState, useContext} from 'react';
import L from "leaflet";
import { MapContainer, Marker, Popup, TileLayer, useMap, LayersControl, LayerGroup, ZoomControl } from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster";
import {iconsName} from '../../data/icons'
import { makeStyles } from '@mui/styles';
import { Context } from "../../context/context";


const useStyles = makeStyles(() => ({
 popUp :{
    padding: '1vh 0',
    '& h4': {
      color: '#52658D',
      margin: '1vh 0 2vh 0',
      padding: 0,
    },
    '& p': {
      // borderBottom: '1px dotted #f2f2f2',
      margin: '1vh 0',
      padding: 0,
      '& span': {
        fontWeight: 'bold',
        color: '#52658D',
      }
    },
  }
}))

const createClusterCustomIcon = (cluster) => {
    var childCount = cluster.getChildCount();

    var c = ' marker-cluster-';
    if (childCount < 3) {
      c += 'tiny';
    } else if (childCount < 5) {
      c += 'tiny2';
    } else if (childCount < 7) {
      c += 'small';
    } else if (childCount < 9) {
      c += 'small2';
    } else if (childCount < 15) {
      c += 'small3';
    } else if (childCount < 50) {
      c += 'medium';
    } else if (childCount < 100) {
      c += 'medium2';
    } else if (childCount < 150) {
      c += 'medium3';
    } else {
      c += 'large';
    }

    return new L.DivIcon({ html: '<div><span>' + childCount + '</span></div>', className: 'marker-cluster' + c, iconSize: new L.Point(40, 40) });
}

 
  

const MemoLayers = ({ readymap, pointslayers}) => {
    

  const { getCateName} = useContext(Context)
   //const pointslayers = comerciosLayer
  


  console.log("POINTLAYERS", pointslayers)
  console.log("redymap", readymap)

    const { popUp } = useStyles()
   
    const checkLayer = (e) => {
      console.log('check')
    }

    const getStoragedViewVisible = (name) => {
      let views = localStorage.getItem('vistas')    
      let arr = JSON.parse(views)      
      let view = arr.result.find(item => item.nombreVista == name);
      return view.visible
    }
    
  
    // const cleanLayersOverlays = () => {
    //   console.log("remove")
    //   let el = document.getElementsByClassName("leaflet-control-layers-overlays")
    //   if(el.length > 0) { 
    //     el[0].remove()
    //   }

    // }

    // cleanLayersOverlays()

   
    useEffect(() => {
      console.log("uef memo")
     
  console.log("POINTLAYERS", pointslayers)
  console.log("redymap", readymap)
    }, [pointslayers, readymap]); 
  
      
     
    return (
    <>
  
    {pointslayers !== undefined & readymap  ?      

      pointslayers.map((layer, index) => {
        console.log('pointslayers', pointslayers)
        console.log('layer', index, layer) 
        //console.log(pointslayers.length, currentLength)
        let loc = layer.locTag 
        let cat = layer.cateTag 
       
        console.log('LAYER', index, loc, cat)

        return (
          <>
            <LayersControl.Overlay
            //(index+1).toString() + '' + 
              name={loc.toUpperCase() + ' - ' + cat}   
              checked={true}
              key={`layerControl${index}` }  
              // onClick={(e) => checkLayer(e)}
               //checked={getStoragedViewVisible(name)}
            >
              <LayerGroup>
                <MarkerClusterGroup
                  showCoverageOnHover={false}
                  disableClusteringAtZoom={12}
                  removeOutsideVisibleBounds={true}
                  iconCreateFunction={createClusterCustomIcon}
                  //onClick={() => selectCluster(layer)}
                >

                  {readymap && 
                    layer.data.map((marker, idx) => {
                  console.log(marker)
                    {/* console.log(layer.data)
                    console.log(marker)
                    console.log(idx) */}
                    let post = [marker.latt, marker.lont];
                    let city = (marker.localidadnombre).toUpperCase()
                    let direction = marker.direccion == null ? '' : (marker.direccion).toUpperCase()
                    let name = (marker.fantasia).toUpperCase()
                    let cate = (marker.categoria).toLowerCase()
                    let rel = (marker.relevamiento).toLowerCase()
                    
                  {/* console.log(rel, name) */}

                    let iconname = iconsName[rel].name
                    
                    if (iconsName[rel].categories[cate] !== undefined) {
                      //console.log(`EXISTE Icono: "${cate}" `) 
                      iconname = iconsName[rel].categories[cate]
                    }
                    {/* else {
                      //console.log(`Icono: "${cate}" NO EXISTE`) 
                    } */}
                    

                    return (
                      <Marker
                        key={idx}                           
                        icon={L.icon({                            
                        iconUrl: `./../../assets/${iconname}`,
                          iconAnchor: [12.5, 41],
                          popUpAnchor: [0, -41],
                          iconSize: [33, 41]
                        })}
                        position={post}
                        //eventHandlers={{ click: (e) => { handleMarker(e) } }}
                      >
                        <Popup  key={idx} style={{ padding: '0 !important', border:'1px solid red !important' }}  >
                          
                        <div className={popUp}>
                            <h4> {name} </h4>
                            <p><span>Categoría:</span> {getCateName(marker.categoria).toUpperCase()}</p>
                            <p><span>Localidad: </span>{city} </p>
                            <p><span>Dirección: </span> {direction}  {marker.numero !== 0 ? `N° ${marker.numero}` : "s/n"}   </p>
                            {marker.telefonopublico !== null && marker.telefonopublico !== '' ? <p><span>Teléfono:</span> {marker.telefonopublico} </p> : null}
                          </div>
                        </Popup>
                      </Marker>
                    );
                  })  
                  }

                </MarkerClusterGroup>
              </LayerGroup>
            
            </LayersControl.Overlay>  
            
          </>) 
        
      })
     
      : 
      <>
      </>
      //<Marker key={"key"} position={position} />
    } 
    </>
      )
}
 
export default React.memo(MemoLayers);