import React, { useContext,  useState, useEffect, useRef, createRef, useMemo } from 'react'
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import { MapContainer, Marker, Popup, TileLayer, useMap, LayersControl, LayerGroup, ZoomControl } from 'react-leaflet';
// import List from '../List/List';
//import Layers from '../Layers/Layers';
// import MapsBase from '../MapsBase/MapsBase';
import MapControl from '../MapControl/MapControl'
//import LayersList from '../LayersList/LayersList';
import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css";
import { makeStyles } from '@mui/styles';
import { Context } from "../../context/context";
import '../../styles/cluster.css';
import {  Hidden } from '@mui/material'
import { ContextEffects } from '../../context/contextEffects'
import { WatermarkControl } from '../Controls/Watermark';
import {DeleteControl} from '../Controls/MapButton'
// import {TextControl} from '../Controls/Text'
import {iconsName} from '../../data/icons'
import MemoLayers from "../Layers/MemoLayers"
import DeleteViews from '../MapControl/DeleteViews';
import { SettingsCellOutlined } from '@material-ui/icons';


//const Layers = lazy(() => import('../Layers/Layers'));
// const MemoLayers = React.memo(Layers);
// const MemoList = React.memo(List);


const useStyles = makeStyles(() => ({
  layersControl: {
    border: '5px solid red !important',
    backgroundColor: 'black',

  },
  popover: {
    border: '1px solid red',
    minHeight: '400px',
    minWidth: '400px',
    height: '100%',
    width: '100%',
  },
  popUp: {
    padding: '1vh 0',
    '& h4': {
      color: '#52658D',
      margin: '1vh 0 2vh 0',
      padding: 0,
    },
    '& p': {
      // borderBottom: '1px dotted #f2f2f2',
      margin: '1vh 0',
      padding: 0,
      '& span': {
        fontWeight: 'bold',
        color: '#52658D',
      }
    },
  }

}))

const Maps = ({ cord, points, pointslayers, readymap, zoom, radio, layerName, visibleLayer }) => {

  const { setNotMain, getRubro, getActiveLayer, setComerciosLayer, deleteViewsContext, cleanSelectors, resetMapStartingPosition,
    localViewsLoaded, getCategoria, categoria} = useContext(Context)
  const { isTabletOrMobile } = useContext(ContextEffects)
  const { layersControl, popUp } = useStyles()

  //const [filters, setFilters] = useState({ pointslayers });
  // const [layerDetails, setLayerDetails] = useState('');
  //const [menuHeight, setMenuHeight] = useState()
  // const [viewsTotal, setViewsTotal] = useState(0)
  // const [viewsActive, setViewsActive] = useState(0)
  // const [isCollapsed, setIsCollapsed] = useState(true)

  const [rubros, setRubros] = useState([]);
  const [isVistas, setIsVistas] = useState(null)
  const [forceUpdate, setForceUpdate] = useState(0)
  
  

  const checkVistasExpire = () => {
    let created =  localStorage.getItem("vistasCreated")
    let expire = localStorage.getItem("vistasExpire")
    let now = new Date().getTime()
    // console.log("created", created)
    // console.log("expire", expire)
    // console.log("now", now)
    if (created !== null){
      if(now > expire){
        localStorage.removeItem("vistasCreated")
        localStorage.removeItem("vistasExpire")
        localStorage.removeItem("vistas")
        resetMapStartingPosition()
      }
    }
  }

  if(categoria == undefined){
    getCategoria()
  }


  useEffect(() => {
    console.log('--------------------uef map triggered-----------------')
    //console.log("CORD---------", cord)
    //console.log("ZOOM---------", zoom)
    //console.log('visibleLayer', visibleLayer)
    
    console.log("pointslayers map", pointslayers)
    console.log("layersControl", layersControl)
    //console.log("IS TABLET OR MOBILE", isTabletOrMobile)    
    cleanSelectors()
    setNotMain(true)
    if (rubros.length !== []) {
      getRubro().then(data => {
        setRubros(data)
      })
    }
    checkVistasExpire()


  }, []); //pointslayers


  const position = [cord.lat, cord.lng];

  const Vista = ({ center, zoom }) => {
    // console.log('VISTA')
    // console.log('center', center)
    // console.log('zoom', zoom)
    if (center) {
      const map = useMap()
      //map.setView(center, zoom)
      map.flyTo(center, zoom)
      return null
    }
  }

  const createClusterCustomIcon = (cluster) => {
    var childCount = cluster.getChildCount();

    var c = ' marker-cluster-';
    if (childCount < 3) {
      c += 'tiny';
    } else if (childCount < 5) {
      c += 'tiny2';
    } else if (childCount < 7) {
      c += 'small';
    } else if (childCount < 9) {
      c += 'small2';
    } else if (childCount < 15) {
      c += 'small3';
    } else if (childCount < 50) {
      c += 'medium';
    } else if (childCount < 100) {
      c += 'medium2';
    } else if (childCount < 150) {
      c += 'medium3';
    } else {
      c += 'large';
    }

    return new L.DivIcon({ html: '<div><span>' + childCount + '</span></div>', className: 'marker-cluster' + c, iconSize: new L.Point(40, 40) });
  }

  // const selectCluster = (layer) => {
  //   console.log('selectCluster LAYER:', layer.data)
  //   setLayerDetails(layer.data)
  // }

  const handleMarker = (e) => {
    // console.log("MARKER CLICKED")
    // console.log(e)
    // const map = useMap()
    // map.flyTo(pos)
  }

 
  const deleteViews = () => {
    // console.log("deleteViews")
    deleteViewsContext()
    setForceUpdate(forceUpdate + 1)
  }
 

  const isPointLayers = () => {
    if(pointslayers !== undefined){
      return true
    }
    else return false
  }

  return (

    <MapContainer
      center={position}
      zoomControl={false}
      zoom={13}
      maxZoom={18}
      style={{
        width: '100vw',
       // minHeight:  isTabletOrMobile ? '-webkit-fill-available !important' : 0,
        height: isTabletOrMobile ? `100%` : `calc(100vh - 120px)`, //top + sombra
        margin: '0',
        padding: '0',
        position: isTabletOrMobile ? 'fixed' : 'relative',

      }}
    >

      <Vista center={position} zoom={zoom} />
     

      <LayersControl
        id="lc"
        position={isTabletOrMobile ? "bottomright" : "topright"}
        collapsed={isTabletOrMobile ?  true : false}
        className={layersControl}
        >

        <LayersControl.BaseLayer checked name="CartoDbMap">
          <TileLayer
            url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Open.Street">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>

          {/* <TextControl position='bottomright' views={viewsTotal} selected={viewsActive}/>  */}
        


        <MemoLayers   pointslayers={pointslayers} readymap={readymap} /> 
        {/*  forceUpdate={forceUpdate}  */}
       
      </LayersControl>
      <WatermarkControl position='bottomleft' />

       {/* <MapControl />  */}     
     
       
      {isPointLayers() !== false &&
      <DeleteControl  position={isTabletOrMobile ? "bottomright" : "topright"}  function={() =>deleteViews()}  />      
      }
      
      <Hidden mdDown><ZoomControl position="bottomright" /></Hidden>

    </MapContainer>

  );
}

export default Maps;











































































































