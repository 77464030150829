import React, { useContext, useEffect } from "react";
import MapController from '../MapController/MapController';
import Sidebar from '../Sidebar/Sidebar';
import { useStyles } from "../../styles/useStyle.js";
import { Context } from "../../context/context";


const Geo = () => {
	const classes = useStyles();
	const { setRouteName, routeName, cleanSelectors} = useContext(Context)


	useEffect(() => {
		if (routeName === 'Catalogo') {
			cleanSelectors()
		}
		setRouteName('Geo')
	}, []);

	return (
		<div className={classes.root} >
			<div className={classes.topContainer}>
				<Sidebar className={classes.container} type={'map'} />
				<MapController />
			</div>
		</div>
	)
}
export default Geo;