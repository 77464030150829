const Headers = [
  { id: 'fantasia', label: 'Nombre', minWidth: 170, 
    format: (value) => value.charAt(0).toUpperCase() + value.slice(1),
  },
  { 
    id: 'direccion',
    label: 'Direccion', 
    minWidth: 100,
   format: (value) => value.toLocaleUpperCase()
   },
  {
    id: 'numero',
    label: 'Numero',
    minWidth: 60,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'localidad',
    label: 'Localidad',
    //label: 'Size\u00a0(km\u00b2)',
    minWidth: 100,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'categoria',
    label: 'Categoria',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];

export default Headers;