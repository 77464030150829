import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from "@mui/material";
import { makeStyles } from '@mui/styles';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const useStyles = makeStyles(() => ({
    container:{
       padding:'5px'
    },
    redes: {
        fontSize: '0.9rem', 
        fontWeight: '400',
        display: 'flex',
        margin: '10px',
        verticalAlign:'center',
       
    },
    icon: {
        marginRight: '0.5vw',
        marginTop: '-0.1vw'
    },
    iconrn: {  
        margin:'auto 0px',  
        marginLeft:'3px',
        marginRight:'5px',  
        height:'18px',
        width:'18px'
    },
    iconente: {      
        height:'18px',
        width:'24px'
    },
    iconmail: {
        color:'#a30505'
    },
    linkNoStyle: {
       
       
        margin:0,
        
    },
    Link: {
        border:"1px solid red"
    }
}));

const Redes = () => {
    const { container, icon, redes, iconmail, linkNoStyle } = useStyles()
    return (
        <>
        <div className={container}>
            <Link href='https://facebook.com/endevam' target="blank"><div className={redes}>
                <FacebookIcon color="primary" className={icon} />                
                <p className={linkNoStyle}>facebook.com/endevam</p>
                
                </div>
                </Link>
            {/* <Link href="https://endevam.rionegro.gov.ar" target="blank"><div className={redes}>
                <img src="/iconRN.png" className={icon + ' ' + iconrn} />endevam.rionegro.gov.ar</div> </Link> */}
            {/* <Link href="https://endevam.com.ar" target="blank"><div className={redes}>  
            <img src="/enteMINI.png" className={icon+ ' ' + iconente} />endevam.com.ar</div> </Link> */}
            <Link href='mailto:gerencia.endevam@gmail.com'>
            <div className={redes}>
                <MailOutlineIcon color='inherit' className={icon + ' ' + iconmail} />
                <p className={linkNoStyle}>gerencia.endevam@gmail.com</p>
            </div>
            </Link>
            {/* <div className={redes}> <WhatsAppIcon style={{ color: 'green' }} className={icon} /><span style={{color:'#1976d2'}}>02946 443997</span></div>*/}
           
            <Link href="https://wa.me/5492984521561" target="_blank" underline="hover">
            <div className={redes}>
                <WhatsAppIcon  style={{ color: 'green' }} className={icon} />
                <p className={linkNoStyle}>2984 521561</p>
                </div>
                </Link>
            </div> 
        </>
    );
}

export default Redes;