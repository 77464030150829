export const iconsName = {
    "comercial": {
        name: "comercial.png",
        categories: {
            "aberturas": "aberturas.png",
            "artesania": "artesania.png",
            "adornos-decoracion": "adornos-decoracion.png",
            "agencia oficial auto": "agencia_oficial_auto.png",
            "agronomias_venta": "agronomias_venta.png",
            "alineacion_balanceado": "alineacion-balanceado.png",
            "alquiler_herramientas": "alquiler_herramientas.png",
            "art_limpieza": "articulos_limpieza.png",
            "artistica-pintura": "artistica-pintura.png",
            "autoservicio": "autoservicio.png",
            "bar": "bar.png",
            "bazar": "bazar.png",
            "bicicleteria": "bicicleteria.png",
            "bijouteire": "bijouteire.png",
            "boliche": "boliche.png",
            "buloneria": "buloneria.png",
            "cafeteria": "cafeteria.png",
            "camping": "camping.png",
            "camping_caza_pesca": "camping_caza_pesca.png",
            "carniceria": "carniceria.png",
            "carpinteria": "carpinteria.png",
            "carpinteria_aluminio": "carpinteria_aluminio.png",
            "casa_de_informatica": "casa_de_informatica.png",
            "centro_medicina_china": "centro_medicina_china.png",
            "centro_psicopedagogo": "centro_psicopedagogo.png",
            "centro_terapia": "centro_terapia.png",
            "centro_terapia_ocupacional": "centro_terapia_ocupacional.png",
            "compraventa": "compraventa.png",
            "consignatario": "consignatario.png",
            "constructora": "constructora.png",
            "contenedores": "contenedores.png",
            "crematorio": "crematorio.png",
            "diseño_grafico": "diseño_grafico.png",
            "distribuidora": "distribuidora.png",
            "estacion_servicios": "estacion_servicios.png",
            "fabrica_hielo": "fabrica_hielo.png",
            "farmacia": "farmacia.png",
            "hogar_ancianos": "hogar_ancianos.png",
            "imprenta-diseño_grafico": "imprenta-diseño_grafico.png",
            "lenceria": "lenceria.png",
            "limpieza": "limpieza.png",
            "marmoleria": "marmoleria.png",
            "minimercado": "minimercado.png",
            "neumaticos": "neumaticos.png",
            "planta_alimento_balanceado": "planta_alimento_balanceado.png",
            "polarizados": "polarizados.png",
            "reparacion_radiadores": "reparacion_radiadores.png",
            "residencia": "residencia.png",
            "restaurant": "restaurant.png",
            "taller_mecanico": "taller_mecanico.png",
            "taxi y remises": "taxi_remises.png",
            "textiles_de_hogar": "textiles_de_hogar.png",
            "torneria": "torneria.png",
            "viandas": "viandas.png",
        }
    },
    "instituciones": {
        name: "instituciones.png",
        categories: {

        }
    },
    "industrial": {
        name: "industrias.png",
        categories: {
            "produccion_de_semillas": "produccion_de_semillas.png",
        //      "": ".png",
        //  "": ".png",
        }
    }
    // "": ".png",
    // "": ".png",
    // "": ".png",

}
