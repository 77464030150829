import React, { useState, createContext } from "react";
import axios from 'axios';
import { ConsoleIcon } from "evergreen-ui";

export const Context = createContext();

export const ContextProvider = (props) => {
	
	const [loadings, setLoadings] = useState(true);
	const [loadingscom, setLoadingscom] = useState(true);
	const [load, setLoad] = useState(true);
	//const api10 = `http://${process.env.REACT_APP_BASE_IP}:5010`;
	const api00 = `https://${process.env.REACT_APP_BASE_URL}:5000`;
	const api30 = `https://${process.env.REACT_APP_BASE_URL}:5030`;

	const [rubro, setRubro] = useState();
	const [categoria, setCategoria] = useState();
	const [localidad, setLocalidad] = useState();
	const [censo, setCenso] = useState();
	const [comercios, setComercios] = useState();
	const [comerciosLayer, setComerciosLayer] = useState();
	const [categoriasObject, setCategoriasObject] = useState()
	//var comerciosLayer
	var comerciosLayerUpd
	var updateLayerName
	var updateLayerVisible
	var arrLayers

	const [comerciostemp, setComerciostemp] = useState();
	const [group, setGroup] = useState();
	const [radio, setRadio] = useState(0);

	const [theloc, setTheloc] = useState('');
	const [therel, setTherel] = useState('');
	const [therub, setTherub] = useState('');
	const [thecat, setThecat] = useState('');
	const [thegru, setThegru] = useState('');
	const [boolg, setBoolg] = useState(false);

	const [layerName, setLayerName] = useState([]);
	const [visibleLayer, setVisibleLayer] = useState([])

	const [readymap, setReadymap] = useState(false);
	const [readylist, setReadylist] = useState(false);

	const [zoom, setZoom] = useState(10)
	const [coord, setCoord] = useState({ lat: -39.309340, lng: -65.862333 });

	const [mobile, setMobile] = useState(false)
	const [notMain, setNotMain] = useState(false)
	const [routeName, setRouteName] = useState('')

	const [busquedaString, setBusquedaString] = useState(null)
	
	const [deleteCount, setDeleteCount] = useState(0)

	const [localViewsLoaded, setLocalViewsLoaded] = useState(false)

	var vistas = []
	var vistaTemp
	var tempStore


	const getLocalidades = async (id) => {
		await setLoadings(true);
		return await axios.get(`${api00}/localidad`)
			.then(res => {
				return res.data
			})
			.catch(error => { console.log(error); setLoadings(true) })
	}

	const getCenso = async (id) => {
		await setLoadings(true);
		return await axios.get(`${api00}/censos`)
			.then(res => {
				return res.data
			})
			.catch(error => { console.log(error); setLoadings(true) })
	}

	const getRubro = async (id) => {
		await setLoadings(true);
		return await axios.get(`${api00}/rubro`)
			.then(res => {
				// console.log('rubroContext', res.data)
				return res.data
			})
			.catch(error => { console.log(error); setLoadings(true) })
	}

	const getGrupo = async () => {
		await setLoadings(true);
		return await axios.get(`${api00}/getGrupo`)
			.then(res => {
				//console.log(res.data)
				return res.data
			})
			.catch(error => { console.log(error); setLoadings(true) })
	}

	const getCategoria = async (id) => {
		console.log('getcategoriaaaaaaaaaaaaa')
		await setLoadings(true);
		return await axios.get(`${api00}/categoria`)
			.then(res => {
				setCategoriasObject(res.data)
				console.log('xx',res.data[0])
				return res.data
			})
			.catch(error => { console.log(error); setLoadings(true) })
	}

	const getCateName = (catename) => {
		console.log("catename", catename)
		if(catename == "Todas"){ 
			return "TODAS"
		}
		else {
		let cate = categoriasObject.find(cat => cat.nombre == catename )
		console.log(cate.nombre_f)
		return cate.nombre_f
		}
        
	}

	const getGroupCombo = async (loc, rel) => {
		await setLoadings(true);
		return await axios.post(`${api00}/getGroupCombo`, { loc, rel })
			.then(res => {
				setLoadings(false);
				return res.data

			})
			.catch(error => { console.log(error); setLoadings(true) })
	}

	const getCateCombo = async (loc, rel, grupo) => {
		await setLoadings(true);
		// console.log("tree ",loc,rel,grupo)
		return await axios.post(`${api00}/getCateCombo`, { loc: loc, rel: rel, grupo: grupo })
			.then(res => {
				setLoadings(false);
				return res.data
			})
			.catch(error => { console.log(error); setLoadings(true) })
	}

	const getComercios = async (loc, rel, grupo) => {
		await setLoadings(true);
		return await axios.get(`${api00}/comerciosGetAll`, { loc, rel, grupo })
			.then(res => {
				return res.data
			})
			.catch(error => { console.log(error); setLoadings(true); })
	}

	const getComerciosLRRC = async (loc, rel, rub, cate, busqueda, boolg, grupo, boolgru, orderby, order) => {
		await setLoadings(true);
		
		console.log("#/////////// context //////////////#")
		console.log("context localidad: ",loc)
		console.log("context relevamiento: ",rel)
		console.log("context rubro: ",rub)
		console.log("context categoria: ",cate)
		console.log("context a buscar: ",busqueda)
		console.log("context a boolg: ",boolg)
		console.log("context a grupo: ",grupo)
		console.log("context a bool de grupo: ",boolgru)
		console.log("#/////////// context //////////////#")
		if (order == null || order  == undefined || order == ''){
			order = 'asc'
		}

		return await axios.post(`${api00}/comerciosGetLRRC`, { loc: loc, rel: rel, rub: rub, cate: cate, busqueda: busqueda, boolg: boolg, gru: grupo, boolgru: boolgru, orderby: orderby, order:order })
			.then(res => {
				setLoadings(false)
				//console.log("context data: ", res.data)
				return res.data
			})
			.catch(error => { console.log(error); setLoadings(true); })
	}

	const postView = async (ciudad, censo, rubro, categoria, busqueda, boolg, grupo, boolgru) => {
		console.log('----------------PostView Params----------------')
		console.log(ciudad, censo, rubro, categoria, busqueda, boolg, grupo, boolgru)
		console.log('--------------------------------------------')
		return await axios.post(`${api00}/createView`, { ciudad: ciudad, censo: censo, categoria: categoria, rubro: rubro, grupo: grupo, boolg: boolg, boolgru: boolgru })
			.then(res => {
				console.log("POST VIEW: ", res.data)
				return res.data
			})
			.catch(error => { console.log(error); })
	}
	const getView = async (vistaNombre) => {
		console.log('Vista Nombre')
		console.log(vistaNombre)
		return await axios.get(`${api00}/getView/${vistaNombre}`)
			.then(res => {
				console.log('GET VIEW', res.data)
				return res.data
			})
			.catch(error => { return Promise.reject(error);})
	}
	const postActiveLayer = async (vistaNombre, loc, cat) => {
		console.log('----loc', loc)
		console.log('----cat', cat)
		return await axios.post(`${api00}/postActiveLayer/${vistaNombre}`, {localidad: loc, categoria: cat}) 
			.then(res => {
				console.log("postActiveLayer: ", res.data)
				return res.data
			})
			.catch(error => { console.log(error); })
	}
	const dropView = async (vistaNombre) => {
		return await axios.delete(`${api00}/dropView/${vistaNombre}`)
			.then(res => {
				console.log("DROP VIEW: ", res.data)
				return res.data
			})
			.catch(error => { console.log(error); })
	}
	const getActiveLayer = async (vistaNombre) => {
		return await axios.get(`${api00}/getActiveLayer/${vistaNombre}`)
			.then(res => {
				console.log("active layer: ", res.data)
				return res.data
			})
			.catch(error => { console.log(error); })
	}
	/*const setView = () =>{
	 zoom =17;
	  map.setView(new L.LatLng(dataIn.latt, dataIn.lont), zoom);
	  setTimeout(() => {
		map.on('click', onMapClicks(dataIn));
	  },400);
	}*/

	const getLocalStorageView = async () => {
		console.log("get localstorage: ",localStorage.getItem('vistas'))
		//IF exist layer in localStorage
		if (localStorage.getItem('vistas') !== null) {
			vistas = JSON.parse(localStorage.getItem('vistas'));
		
			const getVistas = async () => {
			vistas.result.forEach((el, i) => {
				//console.log(el.nombreVista)	
				getActiveLayer(el.nombreVista)
					.then(res => {
						//eliminar capas viejas
						let datalocal = res.data;
						//console.log(datalocal)
						if (datalocal === "CapaSinFecha" && vistas !== { "result": [] }) {
							let nombreVista = { name: el.nombreVista }
							delItemStorageView(nombreVista)
						} else {
							//console.log("la capa existe y debe mostrarse", datalocal)
							if (datalocal[0].active === false) { console.log("no debe mostrarse esta capa") }
							else {//load de layer active on Geoserver through de API Rest 
								getView(datalocal[0].layername)
									.then(gw => {
										

										if (gw !== undefined) {
											//console.log("nombre vista actual: ",datalocal[0].layername)
											let comerciosUpdateLayer = [{ name: datalocal[0].layername, data: gw.data, cateTag: datalocal[0].categoria, locTag: datalocal[0].localidad}] // create new object
											//console.log("capa nueva: ",comerciosUpdateLayer)
											//console.log("comercio layer : ",comerciosLayer)
											let visibleLayerObject = [{ name: datalocal[0].layername, visible: true }]
											//setComerciosLayer(prevState => ({...prevState,name: datalocal[0].layername,data: gw.data}));
											if (!comerciosLayerUpd) {
												comerciosLayerUpd = [...comerciosUpdateLayer]
												//console.log("abajo layer : ",comerciosLayerUpd)	
											} else {
												comerciosLayerUpd = [...comerciosLayerUpd, ...comerciosUpdateLayer]
												//console.log("add layer : ",comerciosLayerUpd)	
											}
											//console.log(datalocal[0].layername)
											if (!updateLayerName) {
												updateLayerName = [datalocal[0].layername]
												updateLayerVisible = [...visibleLayerObject]
											} else {
												updateLayerName = [...updateLayerName, datalocal[0].layername]
												updateLayerVisible = [...updateLayerVisible, ...visibleLayerObject]
											}
										} else {
											let nombreVista = { name: el.nombreVista }
											delItemStorageView(nombreVista)
											console.log(nombreVista)
										}
										//console.log(updateLayerVisible)
										//console.log(updateLayerName)
									})
									.catch(()=> {
											let nombreVista = { name: el.nombreVista }
											delItemStorageView(nombreVista)
											console.log(nombreVista)
										})
									.finally(() => {
										console.log("update layer first: ",comerciosLayerUpd);
										setComerciosLayer(comerciosLayerUpd)										
										})
							}
						}

					})
					//.finally(() => {console.log("update end? : ")})
					.catch(error => {
						//console.log(error); 
						//let nombreVista = {name:el.nombreVista}
						//delItemStorageView(nombreVista)
					})

			})
			
			}

			getVistas()
			.then(res => {
				console.log("-----------COMERCIOS LAYERupd", comerciosLayerUpd)
				setComerciosLayer(comerciosLayerUpd) //sin esto no anda, porque? no hay porque
				setLayerName(updateLayerName)
				setVisibleLayer(updateLayerVisible)
				setReadymap(true)
				//console.log("-----------COMERCIOS LAYER", comerciosLayer)
			})
			
			// console.log("first after add all capas")
			// setComerciosLayer(arrLayers)

			// setTimeout(() => {
			// 	//console.log("update layer : ",comerciosLayerUpd)
			// 	setLayerName(updateLayerName)
			// 	setVisibleLayer(updateLayerVisible)
			// 	//setComerciosLayer(comerciosLayerUpd)
			// 	//setReadymap(true) //activa el mapa
			// 	console.log(layerName)
			// 	console.log(readymap)

			// }, 10);
			//## no enabled//if expired remove from localstorage
			vistaTemp = vistas.result.filter(item => item !== null);
			vistas = { "result": vistaTemp };
		} else if (localStorage.getItem('vistas') === null) {
			vistas = { "result": [] };
		}
	}

	const delItemStorageView = async (itemvista) => {
		//console.log("remove entrada: ",itemvista)
		//console.log("entrada: ",itemvista.name)

		//console.log("remove localstorage: ",JSON.parse(localStorage.getItem('vistas')))
		tempStore = JSON.parse(localStorage.getItem('vistas'));
		if (tempStore !== null || tempStore !== []) {
			vistaTemp = tempStore.result.filter(item => item !== null);
			//without result array
		}
		//tempStore = {"result":vistaTemp};
		//await dropView(itemvista).then(res=> {console.log(res)})
		let i;

		for (i = tempStore.result.length - 1; i >= 0; i -= 1) {
			//console.log("elimina?: ",tempStore.result[i].nombreVista)
			if (tempStore.result[i].nombreVista === itemvista.name) {
				tempStore.result.splice(i, 1);
				if (tempStore.result.length === 0) {
					localStorage.removeItem('vistas');
					vistas = { "result": [] };
				}

			}

		}

		localStorage.removeItem('vistas');
		localStorage.setItem('vistas', JSON.stringify(tempStore));

		//getLocalStorageView();
	}
	const setLocalStorageView = async (itemvista, rel, cate, loc, locTag, cateTag) => {
		// console.log('----------setLocalStorageView------------')
		// console.log('itemvista:', itemvista)
		// console.log('rel', rel)
		
		// const layer = {     sin uso
		// 	"result": [
		// 		{
		// 			censo: rel,
		// 			categoria: cate,
		// 			ciudad: loc,
		// 			nombreVista: itemvista
		// 		}]
		// };

		//setTimeout(() => {
			const vistaarray = {
				"result": [
					{ nombreVista: itemvista, relev: rel, locTag: locTag, cateTag: cateTag, visible:true}
					//vistasInfo
				]
			};
			//const vistaarray2 = { nombreVista: itemvista }
			const vistaarray2 = { nombreVista: itemvista , relev: rel, locTag: locTag, cateTag: cateTag, visible:true}
			let oldVistas;
			// console.log(JSON.parse(localStorage.getItem('vistas')))
			if (localStorage.getItem('vistas') !== null) {
				if (localStorage.getItem('vistas') === '{"result":[]}') {
					//console.log("second time")
					localStorage.setItem('vistas', JSON.stringify(vistaarray));
				} else {
					oldVistas = JSON.parse(localStorage.getItem('vistas'))
					
					//oldVistas.result.push(vistasInfo); ///?
					oldVistas.result.push(vistaarray2);
					//console.log('oldVistas:', oldVistas)					
					localStorage.setItem('vistas', JSON.stringify(oldVistas));
				}
			} else if (localStorage.getItem('vistas') === null) {
				// console.log('localStorage.getItem(vistas) === null')
				let now = new Date().getTime()
				let day =  1 * 24 * 60 * 60 * 1000
				let min = 60 * 1000//1 min 
				// let min =  60 * 1000 //for test				
				localStorage.setItem("vistasCreated", now)
				localStorage.setItem("vistasExpire", now + day)
				localStorage.setItem('vistas', JSON.stringify(vistaarray));
			}
			//getLocalStorageView();
		//}, 200);
	}

	const setSearch = (selector, value) => {
		//Dependiendo de la entrada Selector se conmunatará el valor de la salida value
		switch (selector) {
			case 'LOCALIDAD':
				setTheloc(value)
				return value;
			case 'CENSO':
				setTherel(value)
				return value;	
			case 'RUBRO':
				setTherub(value)
				return value;
			case 'GRUPO':
				setThegru(value)
				return value;
			case 'CATEGORIA':
				setThecat(value)
				return value;
			default:
				return '';
		}
	}

	const setValueSearch = (selector) => {
		switch (selector) {
			case 'LOCALIDAD':
				return theloc;
			case 'CENSO':
				return therel;
			case 'RUBRO':
				return therub;
			case 'GRUPO':
				return thegru;
			case 'CATEGORIA':
				return thecat;
			default:
				return '';
		}
	}

	const changePosition = async (pos, zom) => {
		console.log("changeposition")
		const objectPosition = { lat: pos[0], lng: pos[1] }
		setCoord(objectPosition)
		setZoom(zom)
	}

	const postFormMail = async (form) => {
		return await axios.post(`${api30}/formulario`, form)
			.then(res => {
				console.log(res)
				return res
			})
			.catch(error => {
				 console.log(error); 
				 return "error"
			})
	}

	const cleanSelectors = () => {
		console.log('cleanselectors')		
		setThecat('')
		setTherub('')
		setThegru('')
		setTherel('')
		setTheloc('')
	}


	const getIconPath = async (cate, rel) => {
		console.log('geticonpath', cate, rel)
		return await axios.post(`${api00}/geticonpath`, { cate: cate, rel: rel})
			.then(res => {
				console.log('res.data context:', res.data)
				return res.data
			})
			.catch(error => { console.log(error) })
	}
	

	const deleteViewsContext = async () => {
		// console.log('------deleteViewsContext-----------')
		let vistas = JSON.parse(localStorage.getItem('vistas'))
		// console.log('vistas', vistas)
		vistas.result.forEach((view, i) => {
			//console.log('vistas.nombreVista', view.nombreVista)
			dropView(view.nombreVista)
		})
		localStorage.removeItem("vistasCreated")
		localStorage.removeItem("vistasExpire")
		localStorage.removeItem("vistas")
		setDeleteCount(deleteCount + 1) //fires useeffect on selectors to remove chips on mobile
		cleanSelectors()
		setComerciosLayer()
	}

	const resetMapStartingPosition = () => {
		setTheloc('') //triggers map controller uef
	}	

	return (
		<Context.Provider
			value={{
				getLocalStorageView, delItemStorageView, setLocalStorageView,
				getLocalidades,
				loadings, setLoadings,
				getComercios,
				getCenso, getRubro, getCategoria, rubro, setRubro,
				categoria, setCategoria,
				categoriasObject,
				getCateName,
				localidad, setLocalidad,
				censo, setCenso,
				getComerciosLRRC,
				setSearch, setValueSearch,
				setTheloc,
				theloc, therel, therub, thecat,
				setTherel, setTherub, setThecat,
				comercios, setComercios,
				comerciosLayer,
				setComerciosLayer,
				load, setLoad,
				readymap, setReadymap,
				postView,
				zoom, setZoom, coord, setCoord,
				changePosition,
				getGrupo, group, setGroup, thegru, setThegru,
				radio, setRadio,
				boolg, setBoolg,
				comerciostemp, setComerciostemp,
				getGroupCombo, getCateCombo,
				loadingscom, setLoadingscom,
				getView, postActiveLayer,
				layerName, setLayerName,
				visibleLayer, setVisibleLayer,
				getActiveLayer,
				readylist, setReadylist,
				mobile, setMobile,
				notMain, setNotMain,
				postFormMail,
				routeName, setRouteName,
				cleanSelectors,
				getIconPath,
				busquedaString,setBusquedaString,
				deleteViewsContext,				
				deleteCount,
				resetMapStartingPosition,
				setLocalViewsLoaded,
				localViewsLoaded,
			}}
		>
			{props.children}
		</Context.Provider>
	);
};