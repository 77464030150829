import React, { useState, useEffect, useContext } from 'react'
import { AppBar, Toolbar,  Button, IconButton, Link, MenuItem, SwipeableDrawer } from "@mui/material";
import { makeStyles } from '@mui/styles';
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink } from "react-router-dom";
import HomeIcon from '@mui/icons-material/HomeOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import Logos from "./Logos"
import { Context } from "../../context/context";
import { headersData } from "./../../data/data"


const useStyles = makeStyles(() => ({
  header: {
    width: '100%',
    backgroundColor: "#52658D",
    alignSelf: "flex-start",
    "@media (max-width: 900px)  ": {
      // paddingLeft: 0,
    },
    // border: '1px solid red'
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#FFFEFE",
    justifyContent: "flex-center",
    textAlign: "center",
    marginLeft: '0'
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "18px",
    marginLeft: "auto",
  },
  toolbar: {
    display: "flex",
    flexDirection: 'row',
    maxHeight: '60px',
    maxWidth: '1280px',
    overflow: 'hidden',
    alignItems: 'unset',
    },
 
  navButtons: {
    paddingTop: '12px',
  },
  drawer: {
    display: 'flex',
  },
  drawerContainer: {
    color: '#f5f5f5',
    width: '100vw',
    backgroundColor: '#52658D',
    
  },
  dawerIcons: {
    fontSize: '20px',
    paddingTop: '6px',
    marginRight: '4px'
  },
  drawerOption: {
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid rgba(200,200,200, 0.3)',
    fontSize: '20px',
    fontWeight: '400',
    minHeight: '40px',
    "&:hover, &:focus": {
      color: 'white',
    }
  },
  arrow: {
    color: 'white',
    marginLeft: '30px',
  }
}));

const TopMenu = () => {
  const { header, logo, menuButton, toolbar, drawerContainer, drawer, drawerOption, dawerIcons, navButtons } = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;

  const [hd, setHd] = useState(false)
  const { routeName } = useContext(Context)

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth <= 959
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
    // console.log(window.innerWidth)
     if (window.innerWidth > 1366) {
      setHd(true)
    } else (setHd(false))
  }, [routeName]);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar} style={{ margin: hd === true ? '0 auto' : '0 0', }}>
        {/* width: hd === true ? '1080px' : '', */}
        <div className={logo}>
          <Logos />
        </div>
        <div className={navButtons}>{getMenuButtons()}</div>
      </Toolbar>
    );
  };

  const handleDrawerOpen = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: true }));
  const handleDrawerClose = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: false }));
  //const handleDrawer = (bool) => setState((prevState) => ({ ...prevState, drawerOpen: bool }));

  const displayMobile = () => {
    return (
      <Toolbar style={{ height: '60px', paddingTop: '0', margin: '0', }}>
        <IconButton
          style={{ padding: '0', margin: '0' }}
          {...{
            edge: "start",
            color: 'inherit',
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
          size="large">
          <MenuIcon style={{ color: drawerOpen ? "#52658D" : 'white' }} />
        </IconButton>

        <SwipeableDrawer
          className={drawer}
          {...{
            anchor: "top",
            open: drawerOpen,
            onClose: handleDrawerClose,
            onOpen: handleDrawerOpen,
          }}
        >
          {/* <MenuItem className={drawerOption} onClick={handleDrawerClose}>
            <div className={arrow}><ExpandLessIcon /></div>
          </MenuItem> */}
          <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#52658D', height: '60px' }}>
            <Logos duration={0} />
          </div>
          <div className={drawerContainer}>{getDrawerChoices()}</div>
         </SwipeableDrawer>


        <div style={{ margin: '0 auto', padding: '0', width: '100%', overflow: 'hidden', height: '60px' }}>
          <Logos />
        </div>
      </Toolbar>
    );
  };


  function switchIcon(lab) {
    switch (lab) {
      case 'Inicio': return <HomeIcon />
      case 'Mapa': return <RoomOutlinedIcon />
      case 'Catalogo': return <ListAltOutlinedIcon />
      case 'Descargas': return <GetAppOutlinedIcon />
      case 'Servicios WMS': return <MapOutlinedIcon />
      case 'Contacto': return <EmailOutlinedIcon />
      default: break;
    }
  }

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            color: "inherit",
            style: { textDecoration: "none" },
            key: label,
          }}
        >
          <MenuItem className={drawerOption} onClick={() => handleDrawerClose()}>
            <div className={dawerIcons}>{switchIcon(label)}</div>
            {label.toUpperCase()}</MenuItem>
        </Link>
      );
    });
  };

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Button
          {...{
            key: label,
            color: "inherit",
            to: href,
            component: RouterLink,
            className: menuButton,
          }}
        >
          {label}
        </Button>
      );
    });
  };

  return (
    <>
      {routeName === 'Geo' && mobileView === true ?
        <></>
        :
        <AppBar className={header} position='sticky'>
          {mobileView ? displayMobile() : displayDesktop()}
        </AppBar>
      }
    </>
  );
}



export default TopMenu;
