
import pdf1 from '../documents/Infografía sobre Habilitaciones Comerciales - Diciembre 2020.pdf'
import pdf2 from '../documents/Infografía sobre Habilitaciones Comerciales - Julio 2021.pdf'
import pdf3 from '../documents/Infografía sobre Habilitaciones Comerciales - Diciembre 2021.pdf'
import pdf4 from '../documents/Infografía sobre Habilitaciones Comerciales - Julio 2022.pdf'
import pdf5 from '../documents/Infografía sobre Habilitaciones Comerciales - Diciembre 2022.pdf'
import pdf6 from '../documents/Infografía sobre Habilitaciones Comerciales - Diciembre 2023.pdf'

// import pdf4 from '../../documents/pdf1.pdf'
// import pdf5 from '../../documents/pdf1.pdf'

const data = [
    {
        key: 0,
        alt:'Informe Habilitaciones Comerciales - Diciembre 2020- Geoportal - Endevam - Ente de Desarrollo del Valle Medio - Río Negro',
        nombre: 'Informe Habilitaciones Comerciales - Diciembre 2020',
        texto: '',
        imagen: './imagenes/descargas/pdf1c.jpg',
        descarga: pdf1,
        bgColor:'f06b6c',
    },
    {
        key: 1,
        alt:'Informe Habilitaciones Comerciales - Julio 2021 - Geoportal - Endevam - Ente de Desarrollo del Valle Medio - Río Negro',
        nombre: 'Informe Habilitaciones Comerciales - Julio 2021',
        texto: '',
        imagen: './imagenes/descargas/pdf2c.jpg',
        descarga: pdf2,
    },
    {
        key: 2,
        nombre: 'Informe Habilitaciones Comerciales - Diciembre 2021 - Geoportal - Endevam - Ente de Desarrollo del Valle Medio - Río Negro',
        texto: '',
        imagen: './imagenes/descargas/pdf3c.jpg',
        descarga: pdf3,
    },
    {
        key: 3,
        nombre: 'Informe Habilitaciones Comerciales - Julio 2022 - Geoportal - Endevam - Ente de Desarrollo del Valle Medio - Río Negro',
        texto: '',
        imagen: './imagenes/descargas/julio2022.jpg',
        descarga: pdf4,
    },
    {
        key: 4,
        nombre: 'Informe Habilitaciones Comerciales - Diciembre 2022 - Geoportal - Endevam - Ente de Desarrollo del Valle Medio - Río Negro',
        texto: '',
        imagen: './imagenes/descargas/pdf3.jpg',
        descarga: pdf5,
    },
    {
        key: 5,
        nombre: 'Informe Habilitaciones Comerciales - Diciembre 2023 - Geoportal - Endevam - Ente de Desarrollo del Valle Medio - Río Negro',
        texto: '',
        imagen: './imagenes/descargas/pdf4.jpg',
        descarga: pdf6,
    },

    // {
    //     key: 4,
    //     nombre: 'Mapas Relevamiento Comercial',
    //     texto: '  Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500.',
    //     imagen: '/descargas/img3.jpg',
    //     descarga: pdf5,
    // },
]

export {
    data
}