import React, { useEffect, useState, useContext } from 'react';
import { Grid, Card, Link, Avatar } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { HiOutlineDocumentDownload } from 'react-icons/hi';
import { IoEarth, IoListCircle } from 'react-icons/io5'
import { useMediaQuery } from 'react-responsive'
import Footer from "../Footer/Footer"
import { Context } from "../../context/context";
import { ContextEffects } from "../../context/contextEffects"


const useStyles = makeStyles((theme) => ({
    divx: {
        width: '100%'
    },
    container: {
        display: 'flex',
        margin: '1vh auto',
        marginBottom: '3vh',
        width: '100vw',
        maxWidth: '1280px',
    },
    grid: {
        margin: '0 auto',
    },
    mainDiv1: {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: "#52658D",
        paddingTop: '0',
        paddingLeft: '0',
        minHeight: 'auto',
        height: 'auto',
        width: '100%',
        '&h1': {
            textAlign: 'left',
            lineHeight: '1',
        }
    },
    mainTitle: {
        color: 'white',
        textAlign: 'center',
        fontWeight: 'bold',
        textShadow: '1px 2px 3px #1d1d1d',
        marginBottom: '0',
        marginTop: '0.5vh'
    },
    lead: {
        color: 'white',
        textShadow: '0px 2px 3px #1d1d1d',
        marginTop: '1vh',
        paddingBottom: '3vh',
    },
    cardContainer: {
        width: '280px',
        height: '310px',
        margin: '1vh auto',
        padding: '10px 0',
        justifyItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
    },
    secTitle: {
        textAlign: 'center',
        fontSize: '2rem',
        fontWeight: 'bold',
        marginBottom: '1vh',
        marginTop: '2vh',
        color: 'rgba(82, 101, 141)',
    },
    secText: {
        width: '100%',
        fontSize: '1.2rem',
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: '0',
        paddingBottom: '0'
    },
    icons: {
        height: '100px',
        width: '100px',
        color: '#404e6e'
    },
    iconx: {
        margin: '0 auto',
        height: '100px',
        width: '100px',
        padding: '',
        fontSize: '20px',
        color: 'rgba(82, 101, 141)',
        border: '2px solid rgba(82, 101, 141)',
        '& svg': {
            height: '100px',
            width: '100px',
        },
    },
    iconContainer: {
        width: '100%',
        display: 'flex'
    },
    subGrid: {

        display: 'flex',
        justifyContent: 'space-around',
        maxWidth: '640px'

    },

}));

const Inicio = () => {

    const { container, divx, mainDiv1, mainTitle, lead, cardContainer, secTitle, secText, iconx, iconContainer, subGrid, grid } = useStyles();
    const { setNotMain, setRouteName } = useContext(Context);
    const { animatedDiv } = useContext(ContextEffects)
    const [hovered, setHovered] = useState(['', ''])
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

    useEffect(() => {
        setNotMain(false)
        setRouteName('Inicio')
    }, []);

    const handleHover = (e, key) => {
        setHovered([e.type, key])
    }



    return <>
        <div style={{ maxWidth: '100%' }}>
            <div >
                <div className={mainDiv1} style={{}}>
                    <h1 className={mainTitle} style={{ fontSize: isMobile ? '3rem' : '4rem' }}>GEOPORTAL</h1>
                    <p className={lead} style={{ fontSize: isMobile ? '1.2rem' : '1.5rem' }}>Infraestructura de datos censales y espaciales del Valle Medio y Río Colorado.</p>
                </div>
            </div>
            <div className={divx}>

                <div className={container}>

                    <Grid container direction="row" justifyContent="center" alignItems="flex-start" display='flex' className={grid}>


                        <Grid item xs={1} sm={3} md={2} lg={'auto'}></Grid>
                        <Grid item xs={10} sm={6} md={8} lg={6}>

                            <Grid container className={subGrid}>
                                {animatedDiv(
                                    <Card elevation={3} className={cardContainer} onMouseEnter={(e) => handleHover(e, 'mapa')} onMouseLeave={(e) => handleHover(e,)}>
                                        <Link
                                            {...{
                                                to: "/geo",
                                                component: RouterLink,
                                            }} style={{ textDecoration: "none" }}>
                                            <div className={iconContainer}>
                                                <Avatar className={iconx} style={{ backgroundColor: hovered[0] === 'mouseenter' & hovered[1] === 'mapa' ? '#f5f9ff' : 'white' }}
                                                    children={<IoEarth />} />
                                            </div>
                                            <h3 className={secTitle}>Mapa</h3>
                                        </Link>

                                        <p className={secText}>
                                            Herramienta de interactiva de visualización de información y datos geolocalizados.
                                        </p>
                                    </Card>, 200)}

                                {animatedDiv(
                                    <Card elevation={3} className={cardContainer} onMouseEnter={(e) => handleHover(e, 'catalogo')} onMouseLeave={(e) => handleHover(e,)}>
                                        <Link  {...{
                                                to: "/catalogo",
                                                component: RouterLink,
                                            }} style={{ textDecoration: "none" }}>
                                            <div className={iconContainer}>
                                                <Avatar className={iconx} style={{ backgroundColor: hovered[0] === 'mouseenter' & hovered[1] === 'catalogo' ? '#f5f9ff' : 'white' }}
                                                    children={<IoListCircle />} />
                                            </div>
                                            <h3 className={secTitle} style={{ textDecoration: hovered[0] === 'mouseenter' & hovered[1] === 'catalogo' ? 'underline #f5f9ff' : '' }}>Catalogo</h3>
                                        </Link>
                                        <p className={secText}> Listado actualizado de Comercios, Industrias e Instituciones de la zona. </p>
                                    </Card>, 400)}

                            </Grid>
                        </Grid>
                        <Grid item xs={1} sm={3} md={2} lg={'auto'}></Grid>
                        {/* ////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                        <Grid item xs={1} sm={3} md={2} lg={'auto'} ></Grid>
                        <Grid item xs={10} sm={6} md={8} lg={6}>
                            <Grid container className={subGrid}>
                                {animatedDiv(
                                    <Card elevation={3} className={cardContainer} onMouseEnter={(e) => handleHover(e, 'descargas')} onMouseLeave={(e) => handleHover(e,)}>
                                        <Link  {...{
                                                to: "/descargas",
                                                component: RouterLink,
                                            }} style={{ textDecoration: "none" }}>
                                            <div className={iconContainer}>
                                                <Avatar className={iconx} style={{ backgroundColor: hovered[0] === 'mouseenter' & hovered[1] === 'descargas' ? '#f5f9ff' : 'white' }}
                                                    children={<HiOutlineDocumentDownload />} />
                                            </div>
                                            <h3 className={secTitle}>Descargas</h3>
                                        </Link>
                                        <p className={secText}>Reportes gráficos derivados de la  información obtenida por medios censales y sus respectivas actualizaciones.</p>
                                    </Card>, 600)}
                                {animatedDiv(
                                    <Card elevation={3} className={cardContainer} onMouseEnter={(e) => handleHover(e, 'servicios')} onMouseLeave={(e) => handleHover(e,)}>
                                        <Link  {...{
                                                to: "/servicios",
                                                component: RouterLink,
                                            }} style={{ textDecoration: "none" }}>
                                            <div className={iconContainer}>
                                                <Avatar className={iconx} style={{ backgroundColor: hovered[0] === 'mouseenter' & hovered[1] === 'servicios' ? '#f5f9ff' : 'white' }}
                                                    children={<IoEarth />} />
                                            </div>
                                            <h3 className={secTitle}>Servicios WMS</h3>
                                        </Link>
                                        <p className={secText}>Permite la visualización de información geográfica a partir de una representación de ésta.</p>
                                    </Card>, 800)}
                            </Grid>
                        </Grid>
                        <Grid item xs={1} sm={3} md={2} lg={'auto'}></Grid>
                        {/* </>, 500)} */}
                    </Grid>

                </div>
            </div>
            <Footer />
        </div>
    </>;
}
export default Inicio;