import React, { useState, createContext } from "react";
import { useMediaQuery } from 'react-responsive'
import { Fade } from 'react-reveal';
import { useSpring, animated } from 'react-spring'

export const ContextEffects = createContext();
export const ContextEffectsProvider = (props) => {

    const [leftMenuOpen, setLeftMenuOpen] = useState('')

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
    const is489 = useMediaQuery({ query: '(max-width: 489px)' })



    const animatedDiv = (target, dly) => {
        // console.log('-----------')
        return <animated.div style={useSpring({
            to: { opacity: 1 },
            from: { opacity: 0 },
            delay: dly,
        })}
        >{target}</animated.div>
    }


    function fader(target, duration, delay) {
        return <Fade style={{ width: '100%' }} duration={duration} delay={delay}> {target} </Fade>
    }
    function faderR(target) {
        return <Fade style={{ width: '100%' }} rigth > {target} </Fade>
        //duration={duration} delay={delay}>
    }
    function faderL(target, state, delay) {
        return <Fade style={{ width: '100%' }} left when={state} delay={delay}> {target} </Fade>
        //duration={duration} delay={delay}>
    }

    return (
        <ContextEffects.Provider
            value={{
                isTabletOrMobile,
                isMobile,
                is489,
                leftMenuOpen,
                setLeftMenuOpen,
                fader,
                faderR,
                faderL,

                animatedDiv
            }}>
            {props.children}
        </ContextEffects.Provider>
    );
}