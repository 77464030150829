import React, { useContext, useEffect, useState } from 'react'
import { Context } from "../../context/context";
import { ContextEffects } from "../../context/contextEffects"
import { Link, Grid, Card,  Alert} from '@mui/material'
import SubHeader from './../SubHeader/SubHeader'
import copy from "copy-to-clipboard"
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    container: {
        marginTop: '1vh',
        overflow:'hidden',
       },
    topContainer:{
     
    },
    topDiv: {
        padding:'2vh 1vw 2vh 1vw ',
        marginTop:'1vh',
       // borderTop:'20px solid #52658D',
        '& p': {
            padding:0,
            margin:0,
            textAlign:'left',
            lineHeight:'1.5rem'            
        },
        '& span':{
            color:'#52658D',
            fontWeight:600,
        },
    },   
    itemContainer: {
        margin: '1vh 0',
    },
    card: {
        display: 'flex',
        justifyContent: 'center',
        margin: '1vh auto',
        padding: '0 1vw',
        borderLeft: '20px solid #52658D', //#2a86b9 
        
        maxWidth:'820px',
    },
    g1: {
        justifyContent: 'flex-start',
        display:'flex',
        '& h4': {
            margin:0,
            padding:0,
            color: '#52658D',
            fontSize:'1.05rem',
            fontWeight:500,
        }
    },
    g2: {
        justifyContent: 'flex-start',
        display:'flex',
        overflow:'hidden',
       
        marginLeft:0,
        height:'22px',
        
       
        '& a': {
            display:'block',
            cursor: 'pointer',
            color: '#2a86b9',
            textAlign: 'start',            
            textDecoration:'none',
            whiteSpace: 'nowrap',
            
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          
            
           "&:hover" :{
                color:'#35a8e6'
            }
        }
    },
    snack: {
        background: 'transparent',
        color: '#2a86b9',
        minWidth: '0',
    }
}));


const Servicios = () => {
    const { setNotMain, setRouteName, isTabletOrMobile} = useContext(Context);
    const { container, topContainer, topDiv, card, itemContainer, g1, g2,  link } = useStyles();
    const { animatedDiv } = useContext(ContextEffects)
    const [snackOpen, setSnackOpen] = useState(false)
    const [xClick, setXClick] = useState(null)
    const [yClick, setYClick] = useState(null)

    const handleClick = (e) => {        
        setXClick(e.clientX)
        setYClick(e.clientY)        
        let link = e.target.textContent
        copy(link)
        setSnackOpen(true)
        setTimeout(() => {
            setSnackOpen(false)
        }, 700)
    }

    useEffect(() => {
        setRouteName('Servicios')
        setNotMain(true)
    }, []);

    return (
        <>
            <SubHeader title='SERVICIOS WMS' />
            <Grid container>
                <Grid item xs={1} sm={1} style={{}} ></Grid>
                <Grid item xs={10} sm={10} style={{ maxWidth: '870px', margin: '0 auto' }}>
                    <div>
                        <Grid container className={topContainer}>
                           
                           <Card className={topDiv}> <p> El <span>Servicio de Mapas en Web (WMS)</span> permite la visualización de información geográfica a partir de una representación de ésta, de una imagen del mundo real para un área solicitada por el usuario. 
            Esta representación puede provenir de un archivo vectorial de un SIG, un mapa digital, una ortofoto, una imagen de satélite, entre múltiples posibilidades.
             Puede organizarse en una o más capas de datos que pueden visualizarse u ocultarse una a una. 
            Se puede consultar cierta información disponible y las características de la imagen del mapa. </p>
            </Card>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={1} sm={1}></Grid>
            </Grid>
            
            <Grid container className={container} >
                <Grid item xs={false} sm={1} ></Grid>
                <Grid item xs={12} sm={10} style={{  }}>
                    {animatedDiv(
                        <Card className={card}>
                            <Grid container className={itemContainer}>
                                <Grid className={g1} item xs={12}>
                                    <h4>   Comercios:</h4>
                                </Grid>
                                <Grid className={g2} item xs={12} md={10}>
                                    <Link className={link} onClick={(e) => handleClick(e)}>http://endevam.com.ar:8090/geoserver/endevamWebServices/wms?service=WMS&version=1.1.0&request=GetMap&layers=endevamWebServices%3Aendevam-Comercios&bbox=-180.0%2C-90.0%2C180.0%2C90.0&width=768&height=384&srs=EPSG%3A4326&styles=&format=application/openlayers</Link>
                                </Grid>
                            </Grid>
                        </Card>
                        , 200)}
                    {animatedDiv(
                        <Card className={card}>
                            <Grid container className={itemContainer}>
                                <Grid className={g1} item xs={12} >
                                <h4>  Instituciones:</h4>
                                </Grid>
                                <Grid className={g2} item xs={12} md={10}>
                                    <Link className={link} onClick={(e) => handleClick(e)}>http://endevam.com.ar:8090/geoserver/endevamWebServices/wms?service=WMS&version=1.1.0&request=GetMap&layers=endevamWebServices%3Aendevam-Instituciones&bbox=-66.157319%2C-39.486751%2C0.0%2C0.0&width=768&height=458&srs=EPSG%3A4326&styles=&format=application/openlayers</Link>
                                </Grid>
                            </Grid>
                        </Card>
                        , 400)}
                    {animatedDiv(
                        <Card className={card}>
                            <Grid container className={itemContainer}>
                                <Grid className={g1} item xs={12} >
                                <h4>  Industrias: </h4>
                                </Grid>
                                <Grid className={g2} item xs={12} md={10}>
                                    <Link className={link} onClick={(e) => handleClick(e)}>http://endevam.com.ar:8090/geoserver/endevamWebServices/wms?service=WMS&version=1.1.0&request=GetMap&layers=endevamWebServices%3Aendevam-industrias&bbox=-180.0%2C-90.0%2C180.0%2C90.0&width=768&height=384&srs=EPSG%3A4326&styles=&format=application/openlayers</Link>
                                </Grid>
                            </Grid>
                        </Card>
                        , 600)}
                  
                    {snackOpen &&
                        <Alert variant="filled" severity="success" color="info"  style={{height:'24px',
                        alignItems:'center', position:'absolute', 
                                     top:yClick-50, left:xClick, color:'white'}}>
                            Copiado!
                        </Alert>
                    }
                </Grid>
                <Grid item xs={false} sm={1}></Grid>
            </Grid>

        </>
    )
}
export default Servicios;