import React from 'react'
// import { Context } from "../../context/context";
// import CircularProgress from '@mui/material/CircularProgress';
import {List, ListItem,ListItemText,ListItemAvatar,Avatar,Grid,Link,Hidden,} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FaFacebook, FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa';
import { MdMail } from 'react-icons/md'

const useStyles = makeStyles((theme) => ({
    footContainer: {
        width: '100%',
        height: '100%',
        padding: '4vh 0 0 0',
        backgroundColor: '#415070',
    },
    topFoot: {
    },
    footContainerBottom: {
        borderTop: '1px solid grey',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1vh',
        height: '2vh',
        color: 'rgba(255,255,255,0.6)',
        '& p': {
            padding: '0',
            margin: '0'
        }
    },
    list: {
        marginBottom: '5vh',
        '& li': {
            '& span': {
                color: '#e3e3e3'
            },
            '& p': {
                color: '#999999'
            },
        }
    },
    avatars: {
        padding: '',
        backgroundColor: '#52658d',
        fontSize: '20px',
        color: 'white',
    },
    avatars2: {
        padding: '1px',
        backgroundColor: 'white',
    },
    noticias: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            margin: '2vh auto',
            justifyContent: 'space-around',
            width: '100%',
        },
        '& li': {
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                alignItems: 'center',
                margin: '0 auto',
                padding: '0',
                '& div.MuiListItemAvatar-root': {
                    display: 'flex',
                    minWidth: '40px',
                    width: '40px',
                    margin: '0 auto',
                    padding: '0',
                    '& div.MuiListItemText-multiline': {
                        '& p': {
                            [theme.breakpoints.down('sm')]: {

                            }
                        }
                    }
                }
            },
        }
    },
    title: {
        color: 'white',
        width: '96%',
        borderBottom: '1px solid #e3e3e3',
        margin: '0 auto',
        '& h3': {
            fontWeight: '400',
            padding: '0',
            margin: '0',
            lineHeight: '1.5',
            paddingBottom: '5px'
        },
    }
}))


const Footer = () => {

    const { footContainer, topFoot, footContainerBottom, list, avatars, avatars2, mail, noticias, title } = useStyles();
    return <>
        <Grid container className={footContainer}>
            <Grid item xs={'auto'} sm={'auto'} md={1} xl={2}></Grid>
            <Grid item xs={12} sm={12} md={10} xl={8} className={topFoot} >
                <Grid container>
                    <Grid item xs={1} sm={1} md={1} lg={1}  ></Grid>
                    <Grid item xs={10} sm={5} md={5} lg={4} >
                        <div className={title}>
                            <h3>
                                Contacto
                            </h3>
                        </div>
                        <List className={list}>
                            <ListItem >
                                <ListItemAvatar>
                                    <Avatar className={avatars} children={<FaMapMarkerAlt />} />
                                </ListItemAvatar>
                                <ListItemText primary="Choele Choel, Río Negro" secondary="Ruta Nacional Nº 22 - Km 1000 " />
                            </ListItem>
                            <ListItem >
                                <ListItemAvatar>
                                    <Avatar className={avatars} children={<FaPhoneAlt />} />
                                </ListItemAvatar>
                                <ListItemText primary="2984 - 521561" />
                            </ListItem>
                            <ListItem >
                                <ListItemAvatar>
                                    <Avatar className={avatars} children={<MdMail />} />
                                </ListItemAvatar>
                                <ListItemText primary="gerencia.endevam@gmail.com" color='blue' className={mail} />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={1} sm={'auto'} > </Grid>
                    <Grid item xs={2} sm={1} md={2} ></Grid>
                    <Grid item xs={8} sm={4} md={4} >
                        <List className={list}>
                            <div className={title}>
                                <h3>
                                    <Hidden smDown>  Noticias </Hidden>
                                </h3>
                            </div>
                            <div className={noticias}>
                                <Link href='https://facebook.com/endevam' target="_blank">
                                    <ListItem >
                                        <ListItemAvatar>
                                            <Avatar className={avatars2} children={<FaFacebook fontSize='40px' color='rgb(59, 91, 152)' />} />
                                        </ListItemAvatar>
                                        <Hidden smDown> <ListItemText primary="Facebook" secondary=".com/Endevam" /> </Hidden>
                                    </ListItem>
                                </Link>
                                <Link href="https://endevam.rionegro.gov.ar" target="_blank">
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar className={avatars2} src="/iconRN.png" />
                                        </ListItemAvatar>
                                        <Hidden smDown> <ListItemText primary="Institucional" secondary='endevam.rionegro.gov.ar' /></Hidden>
                                    </ListItem>
                                </Link>
                                <Link href='http://endevam.com.ar' target="_blank">
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar className={avatars2} src="/enteMINI3.png" />
                                        </ListItemAvatar>
                                        <Hidden smDown>  <ListItemText primary="Sitio Web" secondary='endevam.com.ar' /></Hidden>
                                    </ListItem>
                                </Link>
                            </div>
                        </List>

                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={footContainerBottom}>
                <p> © 2022 EnDeVam - Ente de Desarrollo Regional del Valle Medio </p>
            </Grid>
        </Grid>
    </>;
}
export default Footer;