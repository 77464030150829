import { createControlComponent } from "@react-leaflet/core";
import { Control, DomUtil } from "leaflet";

Control.Watermark = Control.extend({
  onAdd: function (map) {
    const img = DomUtil.create("img");
    img.src = "/logo_map3.png";
    img.style.width = "140px"; 
    img.style.maxWidth = "30vw";
    img.style.margin = "0 !important" 
    //img.style.height = "40px";
    return img;
  },

  onRemove: function (map) {},
});

export const WatermarkControl = createControlComponent(
  (props) => new Control.Watermark(props)
);