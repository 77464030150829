import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Search from '../Search/Search';
import { useStyles } from "../../styles/useStyle.js";
import { Context } from "../../context/context";
import { ContextEffects } from '../../context/contextEffects'
import { SearchInput } from "evergreen-ui";
import { IconButton as IconButtonMat, SwipeableDrawer, Grid, Hidden,  Paper, Chip } from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune';
import Fade from 'react-reveal/Fade';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { ConstructionOutlined } from '@mui/icons-material';


const MemoSearch = React.memo(Search);

const InputMemorized = ({ getComerciosLRRC, setSearch, setComercios, local, rel, rub, cate2, search, onChangeInput, maxW }) => {
	
	const changeSearch = e => {
		const { value } = e.target;
		//setTimeout(() => {
		onChangeInput(value)
		//}, 1000); 
	};

	
	return (
		<SearchInput
			placeholder="Buscar..."
			height={40}			
			style={{ width: '160px' }}		
			onChange={(e) => changeSearch(e)}			
			value={search}
		/>
	)
}

const InputMemo = React.memo(InputMemorized);

const Selectors = ({ type }) => {
	const { theloc, setTheloc, therel, setTherel,  setTherub, setThecat, thecat, getGroupCombo, getCateCombo } = useContext(Context);
	const { getLocalidades, getCenso, getComerciosLRRC, postView } = useContext(Context);
	const { setLoadings, setMobile,  setBusquedaString , setLoad} = useContext(Context);
	const { localidad, setLocalidad, censo, setCenso, categoria, setCategoria } = useContext(Context);
	const { comercios, setComercios,  setComerciostemp,  group, setGroup, thegru, setThegru,  setBoolg } = useContext(Context);
	const { setReadymap, setLoadingscom, getView, postActiveLayer, layerName, setLayerName,  setVisibleLayer } = useContext(Context);
	const { setLocalStorageView,  getLocalStorageView, comerciosLayer, setComerciosLayer, routeName, getRubro, deleteCount, setLocalViewsLoaded } = useContext(Context);
	
	
	const { isTabletOrMobile } = useContext(ContextEffects);

	const [local, setLocal] = useState();
	const [tipegru, setTipegru] = useState();

	const [rel, setRel] = useState();
	
	const [rub, setRub] = useState();
	const [cate, setCate] = useState();
	const [cate2, setCate2] = useState();
	
	const [grub, setGrub] = useState();
	const [search, setSearch] = useState();

	// const [colapsable, setColapsable] = useState(true);
	// const [sidecolapse, setSidecolapse] = useState(false);

	// const [anchorEl, setAnchorEl] = useState(null);
	const [drawerOpen, setDrawerOpen] = useState(false);

	let boolgru

	const [rubros, setRubros] = useState([]);

	useEffect(() => {	
		///remove chips 
		setLocal('')
		setRel('')
		setGrub('')
		setCate('')
		setCate2('')
		setSearch('')
	}, [deleteCount]);

	useEffect(() => {
		console.log("uef selectors -----------------------------")
		initialGet()

		if (rubros.length !== []) {
			getRubro().then(data => {
			  setRubros(data)
			})
		  }		//console.log(rel,rub,cate,grub,groups)
		return () => { };
	}, []);

	const initialGet = async () => {
		console.log("initialGet")
		try {
			//promesas
			await getLocalidades()
				.then(data => {
					data.push({ id: 'x', nombre: "Todas" })
					setLocalidad(data)
					console.log(localidad)
				})
			await getCenso()
				.then(data => { setCenso(data) })

			setCategoria([]) //{ id: 0, nombre: 'Seleccionar' }
			setGroup([])// { id: 0, nombre: 'Seleccionar' }

			await setTimeout(() => { setLoad(false) }, 100)
			getLocalStorageView() //busca capas en el localstorage
		} catch (e) {
			console.log(e)
		} finally {
			await setLoadings(false)
			setLoad(false)
		}
	}

	const classes = useStyles();

	const onClickSearch = async (Nombre, label, value) => {
		// console.log('-------------------------------------')
		// console.log('nombre:', Nombre)
		// console.log('label:', label)
		// console.log('value:', value)
		// console.log('-------------------------------------')


		/*Comm
			Click del Hijo Search, recibe valores seleccionados
			el switch acciona la seleciona anidada (como en los combobox)
			tambien llama al API dependiendo de la seleccion y elcampo de busqueda*/
		switch (Nombre) {
			case 'LOCALIDAD':
				setLocal(value)
				setTheloc(value)
				setRub('')
				setRel('')
				setTherel('')
				setThegru('')
				setThecat('')
				setCate2()
				setGrub() 
				setComercios([])
				//await setEstate(prev => ({ ...prev, local: value }));
				//estate.set({ local: value }); estate.merge({ cate: value });

				if (value === 'x') {
					//loc_all: true,	
					console.log('localidad TODAS')
				}
				console.log('localidad', value, rel, rub, cate, search)
				await getComerciosLRRC(value, rel, rub, cate, search)
					.then(data => {
						//setComercios(data); 
						setThecat('')
						setTherub('')
						setThegru('')
						setRub('')
						//setSearch(undefined)
					})
				return '';

			case 'CENSO':
				setTherel(value)
				setRel(value)
				setRub('')
				setThegru('')
				setThecat('')
				setCate2() //acata
				setGrub() //acata
				setComercios([])
				setCategoria([]) //{ nombre: 'vacio', id: '0' }
				await getGroupCombo(local, value)
				//group is an object that contains all "group" ie: 1.bienes, 2.serivicos, 3.alim, 4.agro  etc
					.then(data => {
						console.log("group data", data, value)
						if (value === 1) { //comerciales
							if (data.length === 0) {
								setGroup([{ nombre: 'vacio', id: '0' }])
							} else {
								setGroup(data.containerGroups)
							}
						} else if (value === 2) {
							//aca va alguna modificacion de instituciones ej: en el listado
							if (data.length === 0) {
								setGroup([{ nombre: 'vacio', id: '0' }])
							} else {
								setGroup(data.containerRubs)
							}
						} else if (value === 3) {
							//aca va alguna modificacion de industrias ej: en el listado
							if (data.length === 0) {
								setGroup([{ nombre: 'vacio', id: '0' }])
							} else {
								setGroup(data.containerRubs)
							}
						}
					})
				return '';
			case 'GRUPO':
				console.log('Grupo', value)
				setThegru(value)
				//setGrub(value)
				//
				setTherub('')
				setRub('')
				setThecat('')
				setCate2() //acata
				setBoolg(true)
				setComercios([])
				await getCateCombo(local, rel, value)
					.then(data => {
						console.log('zzzzzzzzzzzzzzdata', data)
						if (rel === 1 && value >= 3) {							
							//value se le restan 2 para que pueda machear con el id_grupo que tienen las categorias
							setGrub(value)
							// setGrub(value)
							setTipegru(2)// !== bienes-servicios
							setComerciostemp(data.ca)							
							
							if (data.ca.length > 1) {									
									data.ca.push({ id: 'x', nombre: "Todas" })
									setCategoria(data.ca)
							}
							else {								
								setCategoria([])
							}
							
						} else {
							console.log('else')
							setTipegru(1) //bienes-servicios
							setGrub(value)
							setRub(value)
							console.log('data: ', data)
							console.log(typeof data)
							if (data === undefined) { //data.length === 0
								setCategoria([]) //{ nombre: 'vacio', id: '0' }
							} else {
								data.ca.unshift({ id: 'x', nombre: "Todas" })
								setCategoria(data.ca)
							}
							//	console.log("rub here? ", rub)
						}
					})
				//await setReadymap(true)          		
				return '';

			case 'CATEGORIA':
				
				console.log('value categoria:', value)
				setThecat(value)
				setComercios([])
				setCate(value)
				setCate2(value)
				if (value === '') {return ''}
				let loc_all
				loc_all = theloc === 'x' ? true : false
				console.log(local)
				//sin activar await postView(local,rel,rub,value)
				let grupo = ''
				if (value === 'x') {
					// console.log('if x')
					// console.log('rel', rel)
					// console.log('tipegru', tipegru)
					// console.log('grub', grub)
					if (rel === 1 && tipegru === 1) { grupo = grub; boolgru = false }
					if (rel === 1 && tipegru === 2) { grupo = grub -2; boolgru = true }
					if (rel === 2 || rel === 3) { grupo = grub; boolgru = false }
					if (type === 'table') {
						await getComerciosLRRC(local, rel, rub, value, search, false, grupo, boolgru)
							.then(data => {
								setComercios(data);
								setLoadingscom(false)
							})
					} else if (type === 'map') {
						await postView(local, rel, rub, value, '', false, grupo, boolgru)
							.then(pw => {
								let comerciosLayerUpd
								if (!layerName || layerName === []) {
									setLayerName([pw.nombrevista])
									setVisibleLayer([{ id: pw.nombrevista, visible: true }])
								} else {
									//setLayerName([...layerName, pw.nombrevista])
									//setVisibleLayer([...visibleLayer, ...[{id: pw.nombrevista,visible: true}]])
								}
								getView(pw.nombrevista)
									.then(gw => {
										console.log(gw.data[0])
										//setComercios(gw.data); 
										
										// console.log('------------')
										// console.log('group object', group)
										// console.log('rubros', rubros)
										// console.log('grup0', grupo)
										let cateTag = group.find(x => x.id === grupo).nombre
										//let cateTag = group.find(x => x.id === grupo).nombre
										
										let locTag = loc_all === true ? 'todas' : gw.data[0].localidadnombre
										console.log('--------------', locTag, cateTag)

										setLocalStorageView(gw.nombreVista, rel, value, local, locTag, cateTag)
										postActiveLayer(pw.nombrevista, locTag, cateTag)
										setLoadingscom(false)

										///el rubro va el postview pero no vuelve en el getview

										let comerciosUpdateLayer = [{ name: pw.nombrevista, data: gw.data, cate_all: true, loc_all: loc_all, rubro: grupo, locTag: locTag, cateTag:cateTag }] // create new object
										if (comerciosUpdateLayer) {
											if (!comerciosLayer) {
												comerciosLayerUpd = [...comerciosUpdateLayer]
											} else {
												if (!comerciosLayerUpd) {
													comerciosLayerUpd = [...comerciosLayer, ...comerciosUpdateLayer]
												} else if (comerciosLayerUpd) {
													comerciosLayerUpd = [...comerciosLayerUpd, ...comerciosLayer]
													comerciosLayerUpd = [...comerciosLayerUpd, ...comerciosUpdateLayer]
												}
											}

										}
									})
									.finally(() => {
										console.log("selectors update layer : ", comerciosLayerUpd);
										setComerciosLayer(comerciosLayerUpd)
									})
							})
					}
				} else {
					if (type === 'table') {
						await getComerciosLRRC(local, rel, rub, value, search, false)
							.then(data => {
								setComercios(data);
								setLoadingscom(false)
							})
					} else if (type === 'map') {
						await postView(local, rel, rub, value, '', false, '', '')
							.then(pw => {
								console.log('pw', pw)
								var comerciosLayerUpd
								if (!layerName || layerName === []) {
									setLayerName([pw.nombrevista])
									setVisibleLayer([{ id: pw.nombrevista, visible: true }])
								} else {
									//agregar nueva campa cuando ya tiene una
									//setLayerName([...layerName, pw.nombrevista])
									//setVisibleLayer([...visibleLayer, ...[{id: pw.nombrevista,visible: true}]])
								}
								getView(pw.nombrevista)
									.then(gw => {
										
										//setComercios(gw.data); 
										
										// console.log('------------')
										// console.log(comerciosLayerUpd)
										let cateTag = gw.data[0].categoria
										let locTag = loc_all === true ? 'todas' : gw.data[0].localidadnombre
										// console.log(locTag, cateTag)
										setLocalStorageView(gw.nombreVista, rel, value, local, locTag, cateTag)
										postActiveLayer(pw.nombrevista, locTag, cateTag)
										setLoadingscom(false)
										let comerciosUpdateLayer = [{ name: pw.nombrevista, data: gw.data, locTag: locTag, cateTag:cateTag }] // create new object
										//console.log(comerciosLayer)
										//console.log(comerciosLayerUpd)
										//console.log(comerciosUpdateLayer)
										if (comerciosUpdateLayer) {
											if (!comerciosLayer) {

												comerciosLayerUpd = [...comerciosUpdateLayer]

											} else {
												if (!comerciosLayerUpd) {
													comerciosLayerUpd = [...comerciosLayer, ...comerciosUpdateLayer]
												} else if (comerciosLayerUpd) {
													comerciosLayerUpd = [...comerciosLayerUpd, ...comerciosLayer]
													comerciosLayerUpd = [...comerciosLayerUpd, ...comerciosUpdateLayer]
												}
											}

										}
										//setComerciosLayer(comerciosLayerUpd)
									})
									.finally(() => {
										//console.log("update layer : ", comerciosLayerUpd);
										setComerciosLayer(comerciosLayerUpd)
									})

							})
					}
				}
				await setReadymap(true)
				setCate('')
				//setRub('')
				return '';
			case 'SEARCH':
				
				onChangeInput('')
				return '';
			default:
				return '';	
		}
	}

	const handleChipClose = (e, s) => {
		//console.log('----chip closed:', s)
		onClickSearch(s, '', '')
		//hasta aca llego(deberia resetera el valor de las cajas)
	}

	// const handleColapse = () => {
	// 	setColapsable((prev) => !prev);
	// }
	// const handleSide = () => {
	// 	setSidecolapse((prev) => !prev);
	// }

	const handleDrawerOpen = (e, b) => {
		if (b === 'open') {		
			setDrawerOpen(true)			
			setMobile(true)
		}
		else {
			setDrawerOpen(null)
			setMobile(false)
		}
	}

	const onChangeInput = (input) => {
		console.log("-----", input, "------")

		getComerciosLRRC(local, rel, rub, cate2, input)
			.then(data => {
				setComercios(data);
			});
		setSearch(input)
		setBusquedaString(input)
		console.log(comercios)

	}

	// const handleBuscar = e => {
	// 	setAnchorEl(e.currentTarget);
	// }

	return (
		<>
			<div>
				{/*loadings ?
		     	(
		    <CircularProgress />
		      	) : (*/}
				{/* <div style={{height: routeName == "Catalogo" ? '60px' : 0 }}></div>   */}
				<Paper elevation={isTabletOrMobile ? 0 : 3}
					style={{
						height: isTabletOrMobile ? '50px' : '60px',
						padding: isTabletOrMobile ? '0' : '0',
						margin: 0,
						display: 'flex',
						alignItems: 'center',
						backgroundColor: routeName === "Catalogo" ? 'white' : 'transparent',
						zIndex: 999,
						position: isTabletOrMobile ? 'fixed' : 'relative',
						width: '100%',
						top:0,
						left: 0,						
					}}>

					<Grid container style={{ maxWidth: '1366px', margin:  '0 auto'}} >
						<Hidden mdDown>
							<Grid item lg={1} />
							<Grid item lg={8} md={9} sm={11} xs={11}>

								<div className={classes.rowContainer}>
									<MemoSearch
										Nombre={'LOCALIDAD'}
										dataset={localidad}
										onClick={onClickSearch}
										value={theloc}
									/>
									<MemoSearch
										Nombre={'CENSO'}
										dataset={censo}
										onClick={onClickSearch}
										value={therel}
									/>
									<MemoSearch
										className={classes.select}
										Nombre={'GRUPO'}
										dataset={group}
										onClick={onClickSearch}
										value={thegru}
									/>
									<MemoSearch
										className={classes.select}
										Nombre={'CATEGORIA'}
										dataset={categoria}
										onClick={onClickSearch}
										value={thecat}
									/>
								</div>
							</Grid>
							<Grid item lg={1} xs={'auto'} />
							<Grid item lg={1} md={3} sm={1} xs={1} style={{}}>
								{routeName !== 'Geo' &&
									<div className={classes.searchContainer}>
										<Hidden mdDown>
											<div className={classes.searcher}>
												<InputMemo
													getComerciosLRRC={getComerciosLRRC}
													setSearch={setSearch}
													setComercios={setComercios}
													local={local}
													rel={rel}
													rub={rub}
													cate2={cate2}
													search={search}
													onChangeInput={onChangeInput}
												/>
											</div>
										</Hidden>
									</div>
								}
							</Grid>
							<Grid item lg={1} xs={'auto'} />
						</Hidden>
						{/* /////////////////////// mobile /////////////////////////////*/}
						<Hidden mdUp>							
							<Grid container style={{ height: '50px', display: 'flex', alignItems: 'center', //padding: '1vw',
								 marginTop: routeName ==='Catalogo' ? '120px' : 0,}}>

								<Grid item xs={1}>
									<IconButtonMat style={{ padding: '0', margin: '0' }}
										edge="start"
										color='inherit'
										aria-label="menu"
										aria-haspopup="true"
										onClick={(e) => handleDrawerOpen(e, 'open')}
									>
										<TuneIcon style={{ color: drawerOpen ? "#52658D50" : '#52658D' }} />
									</IconButtonMat>
								</Grid>
								<Grid item xs={11} style={{ backgroundColor: 'transparent', height: '50px' }}>
									{local === undefined && rel === undefined && grub === undefined && cate2 === undefined && search === undefined ?
										<></>
										:
										<>
											{isTabletOrMobile ?
												<div className={classes.chips}>
													{local === undefined || local === '' ? <></> : <Fade><Chip className={classes.chip}
														label={Object.values(localidad).find(loc => loc.id === local).nombre.toUpperCase()}
														variant="filled" size="small" onDelete={(e) => handleChipClose(e, 'LOCALIDAD')} /></Fade>}
													{rel === undefined || rel === '' ? <> </> : <Fade><Chip className={classes.chip}
														label={Object.values(censo).find(cen => cen.id === rel).nombre.toUpperCase()}
														variant="filled" size="small" onDelete={(e) => handleChipClose(e, 'CENSO')} /></Fade>}
													{grub === undefined || grub === '' ?
													 <></> :
													  <Fade>
													  <Chip className={classes.chip}
														label={Object.values(group).find(r => r.id === grub).nombre.toUpperCase()}
														variant="filled" size="small" onDelete={(e) => handleChipClose(e, 'GRUPO')} />
														</Fade>}

													{cate2 === undefined || cate2 === '' ? <> </> : <Fade><Chip className={classes.chip}
														label={Object.values(categoria).find(cat => cat.id === cate2).nombre.toUpperCase()}
														variant="filled" size="small" onDelete={(e) => handleChipClose(e, 'CATEGORIA')} /></Fade>}

													{search === undefined || search === '' ? <> </> : <Fade><Chip className={classes.chip}
														label={search.toUpperCase()}
														variant="filled" size="small" onDelete={(e) => handleChipClose(e, 'SEARCH')} /></Fade>}
												{/* <button onClick={()=>console.log(cate2, categoria)}>LOG</button> */}
												</div>
												:
												<></>
											}

										</>
									}
								</Grid>



							</Grid>



							{/* </Paper> */}

							<SwipeableDrawer
								style={{}}
								disableDiscovery={true}
								disableSwipeToOpen={true}
								{...{ anchor: "left", open: drawerOpen,
								 onClose: () => setDrawerOpen(false), onOpen: () => setDrawerOpen(true), }}>
								{/* <MenuItem className={drawerOption} onClick={handleDrawerClose}>
								<div className={arrow}><ExpandLessIcon /></div>
							</MenuItem> */}
								<Grid>
									<Paper style={{
										borderRadius: "0 !important",
										height: '50px',
										padding: '0',
										margin: '0',
										display: 'flex',
										backgroundColor: "transparent",
										textAlign: 'center',
										justifyContent: 'flex-start',
									}}>
										<h3
											style={{
												verticalAlign: 'middle',
												color: '#52658D',
												fontWeight: 'bold',
												textShadow: '1px 2px 3px #DDD',
												margin: 'auto auto',
												padding: 0,
											}}>GEOPORTAL</h3>
									</Paper>
									<div style={{ width: '180px' }}>
										<div style={{ margin: '2vh  1.5vh 2vh 1.5vh' }}>
											<Search
												Nombre={'LOCALIDAD'}
												dataset={localidad}
												onClick={onClickSearch}
												value={theloc}
											/>
										</div>
										<div style={{ margin: '2vh  1.5vh 2vh 1.5vh' }}>
											<Search
												Nombre={'CENSO'}
												dataset={censo}
												onClick={onClickSearch}
												value={therel}
											/>
										</div>
										<div style={{ margin: '2vh  1.5vh 2vh 1.5vh' }}>
											<Search
												Nombre={'GRUPO'}
												dataset={group}
												onClick={onClickSearch}
												value={thegru}
											/>
										</div>
										<div style={{ margin: '2vh  1.5vh 2vh 1.5vh' }}>
											<Search
												Nombre={'CATEGORIA'}
												dataset={categoria}
												onClick={onClickSearch}
												value={thecat}
											/>
										</div>
									</div>

									{routeName === "Geo" ?
										<div style={{
											borderTop: '1px dotted #EEE',
											display: 'flex',
											position: 'absolute',
											bottom: '0',											
											height: '30px',
											padding: '0',
											margin: '0',
											alignItems: 'center',
											marginLeft:'40px'
										}}>
											<Link to="/" style={{ textDecoration: 'none', margin:0, padding:0 }}>
												<IconButtonMat
													style={{ padding: '0', margin: '0', display: 'flex'}}
													edge="start"
													color='inherit'
													aria-label="menu"
													aria-haspopup="true"
												>
													<HomeOutlinedIcon  style={{ padding: '0', margin: '0', marginTop:'-2px' , color: '#52658D', fontSize: 20 }} />
													<p
														style={{
															padding: '0', margin: '0',
															textDecoration: 'none',
															fontSize: '18px',
															color: '#52658D'
														}}>INICIO</p>
												</IconButtonMat>
											</Link> </div> : <></>}
								</Grid>
								{routeName !== 'Geo' &&
									<Grid style={{ display: 'flex', justifyContent: 'center' }}>
										<InputMemo
											getComerciosLRRC={getComerciosLRRC}
											setSearch={setSearch}
											setComercios={setComercios}
											local={local}
											rel={rel}
											rub={rub}
											cate2={cate2}
											search={search}
											onChangeInput={onChangeInput}
											maxW='170px' //width
										/>
									</Grid>
								}
							</SwipeableDrawer>
						</Hidden>
					</Grid>
				</Paper>

				{/* 
									{!colapsable ?
							     	(
								<IconButton size="small" onClick={handleColapse}>
					          		<ArrowDownwardIcon fontSize="inherit" />
					        	</IconButton>
								) : (
								<div className={classes.searcher}>
									<Collapse in={colapsable}>
									<InputMemo 
										getComerciosLRRC={getComerciosLRRC}
										setSearch={setSearch}
										setComercios={setComercios}
										local={local}
										rel={rel}
										rub={rub}
										cate2={cate2}
										search={search}
										onChangeInput={onChangeInput}
									/>
									</Collapse>
									<IconButton size="small" onClick={handleColapse}>
					          			<ArrowDropUpIcon fontSize="inherit" />
					        		</IconButton>	
								</div>
								)}
							*/}
			</div>
			<Grid container>
			</Grid>
		</>
	);
}
export default Selectors;